import React, { Component } from 'react'
import FollowerService from "../../services/followerListService";
import FollowinfService from "../../services/followingListService";
import sendMessage from "../../services/sendMessageService";
import getMessage from "../../services/getMessageService";
import MessagesBox from "./MessagesBox";
import Globals from "../../Global/Constant";
import { connect } from "react-redux";
import Cookies from "js-cookie";

export class MessageComponent extends Component {

    state = {
        list: [],
        selectedUser:null,
        textMessage:'',
        messages:null,
        showLoader:false,
        searchText:'',
        socket:null,
        user:null
    }

    async  componentDidMount () {
        console.log('socket--',this.props.socket);
        await this.getListData('');
        if(this.state.list !== undefined  && this.state.list.length > 0) {
            this.setState({selectedUser: this.state.list[0]});
        }
        this.getMessage();
    }

    getListData = async (text) =>{

        const url1 = Globals.api + '/follow/follower-list?q='+text;
        const url2 = Globals.api + '/follow/following-list?q='+text;
        await FollowerService.getList(url1).then(res => {
            this.setState({
                list: res.item
            })
        });
        await FollowinfService.getList(url2).then(res => {
            // let stateList=this.state.list;
            // let item = res.item.filter(function(item){return stateList.filter(function(i){return i})});
            const valArray = this.state.list.map((item, index) => item['user_id'])
            const uniqueVal = res.item.filter((item, index) => valArray.indexOf(item['user_id']) === -1);
            this.setState({
                list: this.state.list.concat(uniqueVal)
            })
        })
    }
    handleClick = (item) => {
        this.getMessage(item.user_id);
        this.setState({selectedUser:item});
    }

    getList = () => {
        // console.log("item.id == item2.id", this.state.list, this.props.newMessageList)
        var list = [];
        if(this.state.list !== undefined  && this.state.list.length > 0) {
            list = this.state.list.map((item, i) =>
                <div key={i} style={{cursor:"pointer"}} className={(this.state.selectedUser == item ||this.state.selectedUser==null)?"message_active_box mb-4 hover_active":"message_active_box mb-4 "} onClick={()=>this.handleClick(item)}>

                    <div className="active_name d-flex mb-3">
                        <div className="active_img">
                            <span className="active_tag"></span>
                            <img src={item.profile_image} className="img-fluid icon-image"/>
                        </div>
                        <div className="message_name_div d-flex justify-content-between flex-grow-1">
                            <div className="flex-grow-1">
                                <h4>{item.name}</h4>
                                <p>Offline</p>
                            </div>
                            {/*<span className="post_time">3h ago</span>*/}
                        </div>
                    </div>
                    {
                        (this.props.newMessageList !== undefined)?
                        this.props.newMessageList.map((item2, i)=>{
                            return (item.user_id == item2.sender_id)?
                                <div key={i} className="d-flex align-items-center justify-content-between">
                                <p className="text-overflow-hidden">{item2.message}</p>
                                </div>
                                :'';
                        }):''
                    }
                    {/*<div className="d-flex align-items-center justify-content-between">*/}
                    {/*    <p>Analysis of foreign experience...</p>*/}
                    {/*    <span className="notify_ic">2</span>*/}
                    {/*</div>*/}
                </div>);
        }
        return list;
    }

    onTextChanged = async (e) => {
        const value =  e.target.value;
        this.setState(() => ({textMessage: value}));
    }

    sendMessage =async () =>{
        if(this.state.textMessage && this.state.selectedUser)
        {
            const data = await sendMessage.sendMessageService(this.state.selectedUser.user_id,this.state.textMessage);
            await this.sendMessageOnSocket();
            this.setState({textMessage: ''});
            this.getMessage();
        }
    }

    sendMessageOnSocket = async ()=>{
        console.log('sendMessageOnSocket');
        const socket = this.props.socket;
        console.log('socket',socket);
        await socket.emit('new_message', {
            to: this.state.selectedUser.user_id,
            from: JSON.parse(Cookies.get('user')).id,
            message: this.state.textMessage
        });
    }

    getMessage =async (user_id) =>{
        this.setState({showLoader: true});
        const reciver_id = (user_id?user_id:( this.state.selectedUser?this.state.selectedUser.user_id:null));
        if(reciver_id) {
            let data = await getMessage.getMessageService(reciver_id);
            let currentMessage = this.props.newMessageList.filter(item => item.sender_id == reciver_id);
            data.item.concat(currentMessage);
            this.setState({messages: data.item});
        }
        this.setState({showLoader: false});
    }

    onSearchTextChanged = async (e) => {
        const value =  e.target.value;
        this.setState(() => ({searchText: value}));
        if(e.target.value == '')
        {
            this.getListData('');
        }
    }
    onSearchText=(e)=>
    {
        if (e.key === 'Enter' && this.state.searchText.length>2) {
            this.getListData(this.state.searchText);
        }
    }

    render() {
        const { textMessage, messages, searchText } = this.state;
        return (
            <>
                <div className="col-xl-4 col-lg-4 col-md-5 p-0">
                    <div className="message_active_area bg_grey px-4 pt-5 pb-0">
                        <div className="search_filed p-0">
                            <i className="fas fa-search"></i>
                            <input type="text" name="" onKeyDown={this.onSearchText} value={searchText} onChange={this.onSearchTextChanged} className="form-control" placeholder="Enter for search…"/>
                        </div>
                        <br />
                        {/*<div className="sorted_area d-flex flex-wrap justify-content-between align-items-center">*/}
                        {/*    <span>Sort By: <a href="#" className="">Latest First <i*/}
                        {/*        className="fas fa-angle-down"></i></a></span>*/}
                        {/*    <span>New <a href="#" className="plus_ic"></a></span>*/}
                        {/*</div>*/}
                        <div className="scroll_div">
                            {this.state.list ? this.getList() : null}
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-7 p-0">
                    <div className="my_message_wrap">
                        <div className="chat_head">
                            <div className="active_name d-flex">
                                <div className="active_img">
                                    <span className="active_tag"></span>
                                    <img src={this.state.selectedUser!=null?this.state.selectedUser.profile_image:null} className="img-fluid icon-image"/>
                                </div>
                                <div
                                    className="message_name_div d-flex justify-content-between flex-grow-1 align-items-center">
                                    <div className="flex-grow-1">
                                        <h4>{this.state.selectedUser!=null?this.state.selectedUser.name:null}</h4>
                                        <p>Offline <span>{/*Last seen 3 hours ago */}</span></p>
                                    </div>
                                    {/*<div className="chat_user_ic">*/}
                                    {/*    <a href=""><i className="fas fa-phone-alt"></i></a>*/}
                                    {/*    <a href=""><i className="fas fa-video"></i></a>*/}
                                    {/*    <a href=""><i className="fas fa-ellipsis-h"></i></a>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                        <div className="chat_box scroll_div scroll_till_last">
                            <div className="chat_listing d-flex flex-column" >
                                {messages && messages.length >0 ?<MessagesBox showLoader={this.state.showLoader} messages={this.state.messages} selectedUserId={this.state.selectedUser.user_id}/>:''}
                            </div>
                        </div>
                        <div className="chat_ft">
                            <div className="chat_field">
                                {/*<a href="#"><i className="fas fa-paperclip"></i></a>*/}
                                <input type="text" name="" className="form-control"
                                       placeholder="Type your message here..." value={textMessage} onChange={this.onTextChanged}/>
                                    <div className="chat_ics">
                                        {/*<a href=""><i className="fas fa-grin"></i></a>*/}
                                        {/*<a href=""><i className="fas fa-microphone-alt"></i></a>*/}
                                        <a href="#!" onClick={this.sendMessage} className="circle_seagreen"><i
                                            className="fas fa-paper-plane"></i></a>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        socket: state.socket.socket,
        newMessageList: state.socket.new_message_list
    }
};
export default connect(mapStateToProps, null)(MessageComponent);