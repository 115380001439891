import http from "./httpService";
import Cookies from "js-cookie";

const apiEndpoint = process.env.REACT_APP_API_URL ;
const tokenKey = "token";

http.setJwt(getJwt());

export async function login(email, password) {
  const {data} = await http.post(apiEndpoint+ "/auth", { email, password });
  localStorage.setItem(tokenKey,  data.data.token);
  localStorage.setItem('user',  JSON.stringify(data.data.user));
  Cookies.set('accessToken', data.data.token)
}

export async function forget(email) {
  const { data } = await http.post(apiEndpoint+ "/request-password-reset", { email });
  return data;
}

export async function setforgetPassword(email, code, password) {
  const { data } = await http.post(apiEndpoint+ "/set-password", { email, code, password});
  return data;
}

export async function changePassword(password) {
  const { data } = await http.post(apiEndpoint+ "/user/change-password", password);
  return data;
}

export async function register(user) {
  const res = await http.post(apiEndpoint+ "/signup", user);
  const {data} = res;
  localStorage.setItem(tokenKey,  data.data.token);
  localStorage.setItem('user',  JSON.stringify(data.data.user));
  Cookies.set('accessToken', data.data.token)
  return res;
}


export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const user = localStorage.getItem('user');
    return user;
    // return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  loginWithJwt,
  register,
  logout,
  getCurrentUser,
  getJwt,
  forget,
  setforgetPassword,
  changePassword
};
