import API from "../Global/PrivateAPI";
import Globals from "../Global/Constant";

const placeVeenList = {
    getList: function(){
        return API.get(Globals.api + '/place/people-are-veeing').then(data => {
            return {item: (data.data !== undefined && data.data.length > 0) ? data.data : []}
        });
    }
}

export default placeVeenList;