import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL ;

export async function viewNotificationService(id) {
  const {data} = await http.get(apiEndpoint+ "/notification/notification-view", {params:{id :id}});
  return data.data;
}

export default {viewNotificationService};
