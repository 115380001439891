import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL ;

export async function viewMessageService(id) {
  const {data} = await http.get(apiEndpoint+ "/message/message-receive", {params:{id :id} });
  return data.data;
}

export default {viewMessageService};
