import API from "../Global/PrivateAPI";
import Globals from "../Global/Constant";

const profile = {
    getdetail: function(){
        return API.get( Globals.api + '/profile').then(data => {
            return {data: (data.data !== undefined) ? data.data : [] };
        });
    }
}

export default profile;