import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL ;


export function getList() {
    return http.get(apiEndpoint+'/review');
}


export default {
    getList
};


