import { SELECT_CATEGORY} from './type';

export function Select(category) {
    return {
        type: SELECT_CATEGORY,
        category: category
    };
}





