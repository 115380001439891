import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL ;

export async function getLatestMessageService() {
  const {data} = await http.get(apiEndpoint+ "/message/latest-message", {});
  return data.data;
}

export default {getLatestMessageService};
