import React, { Component } from 'react'
import { connect } from "react-redux";
import { PROFILE_LOAD } from '../../store/reducer/Profile/type';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';

import SocialButton from '../../component/SocialLogin/index'

import SignUp from '../../component/SIgnUp';
import SignIn from '../../component/SignIn';
import GoogleLogin from 'react-google-login';
import Globals from '../../Global/Constant'
import FacebookLogin from 'react-facebook-login';


class Login extends Component {
    constructor(props) {
        super(props);
        Cookies.get('accessToken') == null ? this.props.history.push('/') : this.props.history.push('/home');
    }
    state = {
        'redirect': true,
        'ShowloginForm': false,
    }
    handleClick = () => {
        this.setState({
            ShowloginForm: true
        })
    }
    responseFacebook = (response) => {
        console.log('response', response);
        if (response.name) {
            this.socialLogin(response.first_name, response.last_name, response.email)
        }
    }

    responseGoogle = (response) => {
        if (response.error) {
            console.log('google-response',response)
            return false
        }
        this.socialLogin(response.profileObj.givenName, response.profileObj.familyName, response.profileObj.email)
    }

    socialLogin = (firstname, lastname, email) => {
        fetch(Globals.api + '/social-login', {
            method: 'post',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            }),
            body: '&fname=' + firstname + '&lname=' + lastname + '&email=' + email + '&platform=web&dob=' + '&dob=1995-04-30'
        }).then(res => res.json()).then((data) => {
            if (data.data.token) {
                localStorage.setItem('token',  data.data.token);
                localStorage.setItem('user',  JSON.stringify(data.data.user));
                Cookies.set('accessToken', data.data.token)
                this.setState({ loading: false })
                window.location.href = Globals.appHomePage;
            } else {
                this.setState({ loading: false, uploadValue: 'Sign Up' })
                this.setState({ errors: [data.data.message] })
            }
        }).then(console.log)
    }


    componentDidMount() {
        this.props.action('none');
    }
    render() {
        const handleFbLogin = (user) => {
            console.log(user)
        }

        const handleFbLoginFailure = (err) => {
            console.error(err)
        }
        const handleGoogleLogin = (user) => {
            console.log(user)
        }

        const handleGoogleLoginFailure = (err) => {
            console.error(err)
        }
        return (
            <section className="signup">
                <div className="container">
                    <div className="row signup-top">
                        <div className="col-md-12">
                            <Link to='/'><img src="assets/images/signup-logo.png" alt="" /></Link>
                        </div>
                    </div>
                    <div className="row signup-middle">
                        <div className="col-lg-6 col-md-12 signup-middle-left">
                            <div className={(this.state.ShowloginForm) ? "d-none detail-box" : "detail-box"}>
                                <h6>Join us today</h6>
                                <h1>Veen Away!</h1>
                                <p>Sign up now and be a part of the World’s <br /> fastest growing online community.</p>
                                <div className="social-button-container">
                                    {/* <Link to="/" className="btn social-btn google-btn">
                                        <div className="icon">
                                            <i className="fab fa-google fa-2x"></i>
                                            <i className="fab fa-google fa-2x"></i>
                                        </div> */}
                                    <GoogleLogin
                                        clientId={Globals.gmailLoginKey}
                                        buttonText="Sign in with Google"
                                        onSuccess={(response) => { this.responseGoogle(response)}}
                                        onFailure={(response) => { this.responseGoogle(response)}}
                                        cookiePolicy={'single_host_origin'}
                                        className="btn social-btn google-btn"
                                    />

                                    {/* <span>Sign in with Google</span> */}
                                    {/* </Link> */}
                                    <FacebookLogin
                                        appId={Globals.facebookAppId}
                                        autoLoad={false}
                                        fields="name,email,picture,first_name,last_name"
                                        callback={(response) => { this.responseFacebook(response)}}
                                        cssClass="btn social-btn facebook-btn"
                                        icon="fa-facebook" />

                                    {/* <Link to='/' className="btn social-btn facebook-btn">
                                        <div className="icon">
                                            <i className="fab fa-facebook-f"></i>
                                        </div>
                                        <span>Sign in with Facebook</span>
                                    </Link> */}
                                    <Link to="/" onClick={this.handleClick} className="btn social-btn email-btn">
                                        <div className="icon">
                                            <i className="fas fa-envelope-open-text"></i>
                                        </div>
                                        <span>Sign in with Email</span>
                                    </Link>
                                </div>
                            </div>
                            <div className={(this.state.ShowloginForm) ? "d-block signin-box" : "d-none"}>
                                <SignIn />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 signup-middle-right">
                            <SignUp />
                        </div>
                    </div>
                    <div className="row signup-bottom">
                        <div className="col-12">
                            <p>Copyright © 2020. VeenMe. All Right Reserved</p>
                        </div>
                    </div>

                </div>
            </section>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        action: (display) => dispatch({
            type: PROFILE_LOAD,
            display: display
        })
    }
};

export default connect(null, mapDispatchToProps)(Login)