import React, { Component } from 'react'
import { getPlacePost } from "../../services/placeService";
import {Link} from "react-router-dom";

export default class Places extends Component {

    state = {
        post: [],
        loading: true
    }

    getPost = async (value) => {
        let { data } = await getPlacePost();
        this.setState({
            post: data.data,
            loading: false
        })

    }
    componentDidMount() {
        this.getPost();
    }

    render() {
        return (
            <div className={'timeline_content'}>
                {this.state.loading ? <div className="img_container2"><i className="fa fa-spinner fa-spin spin1"></i></div> : ""}
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.css" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick-theme.min.css" />
                <div className={'tab-content'} id={'myTabContent'}>
                    {this.state.post.map((item, i) =>
                        <div key={i} className="tab-pane fade places-section-box veen-section-box active show" id="places" role="tabpanel"
                            aria-labelledby="places-tab">
                            <div className="timeline_box places-box">
                                <div className="box_inner d-flex align-items-start">
                                    <img src={item.image[0]} className="img-fluid img_circle" />
                                    <div className="box_inner_content">
                                        <div className="name_div">

                                            <Link to={"place/" + item.place_id}>
                                                <h4 className="d-'inline-block'">
                                                    {item.location}
                                                </h4>
                                            </Link>

                                            <br />
                                            <span className="places-k"><span>{item.veens}</span> Veens</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="ft-place-holder product-slider">
                                    <div className="ft_box ft-box-place-main">
                                        <div className="ft_img slider-for slick-initialized slick-slider">
                                            <div className="slick-list draggable">
                                                <div className="slick-track" style={{ opacity: 1, width: '1095px' }}>
                                                    <div className="slick-slide slick-current slick-active" data-slick-index="0"
                                                        aria-hidden="false"
                                                        style={{ width: '365px', position: 'relative', left: '0px', top: '0px', zIndex: 999, opacity: 1 }}>
                                                        <div>
                                                            <div style={{ width: '100%', display: 'inline-block' }}>
                                                                <img src={item.image[0]}
                                                                    className="img-fluid" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        (item.image[1] !== undefined || item.image[2] !== undefined) ?
                                            <div className="ft-box-place-sub">
                                                <div className="ft_img slider-nav slick-initialized slick-slider slick-vertical">
                                                    <div className="slick-list draggable" style={{ height: '78px', padding: '50px 0px' }}>
                                                        <div className="slick-track"
                                                            style={{ opacity: 1, height: '78px', transform: 'translate3d(0px, 0px, 0px)' }}>
                                                            <div className="slick-slide slick-current slick-center" data-slick-index="0"
                                                                aria-hidden="true" style={{ width: '91px' }}>
                                                                <div>
                                                                    <div style={{ width: '100%', display: 'inline-block' }}>
                                                                        <img src={item.image[0]} className="img-fluid" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                item.image[1] !== undefined ? <div className="slick-slide" data-slick-index="1" aria-hidden="true"
                                                                    style={{ width: '91px' }}>
                                                                    <div>
                                                                        <div style={{ width: '100%', display: 'inline-block' }}>
                                                                            <img src={item.image[1]} className="img-fluid" />
                                                                        </div>
                                                                    </div>
                                                                </div> : ''
                                                            }
                                                            {
                                                                item.image[2] !== undefined ?
                                                                    <div className="slick-slide slick-center"
                                                                        data-slick-index="2"
                                                                        aria-hidden="true" style={{ width: '91px' }}>
                                                                        <div>
                                                                            <div style={{
                                                                                width: '100%',
                                                                                display: 'inline-block'
                                                                            }}>
                                                                                <img src={item.image[2]}
                                                                                    className="img-fluid" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : ''
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    }
                                </div>

                                <div className="comment-box-post places-comment-box-post">
                                    <ul>
                                        <li>
                                            {item.user.map((itemuser, i) => <img style={{ marginRight: (item.user.length <= 1 ? "" : "-20px") }} className="img-fluid img_circle" src={itemuser.profile_image} alt="" />)}
                                        </li>
                                        <li className="center" style={{ paddingLeft: '50px' }}>
                                            <span className="places-k"><span>{item.veens}</span> Visited this place</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
