import React, { Component } from 'react'
import FollowingService from "../../services/followingListService";
import Globals from "../../Global/Constant";
import Cookies from "js-cookie";
import {Link} from "react-router-dom";
import {ToastsStore} from "react-toasts";
import followUserService from "../../services/followUserService";

export default class Following extends Component {
    state = {
        followingList: [],
        nextUrl: null,
        loading: false,
        totalCount:0
    }
    componentDidMount() {

        var url = (this.props.userId) ? Globals.api + '/follow/following-list?q=&user_id=' + this.props.userId : null ;
        FollowingService.getList(url).then(res => {
            this.setState({
                followingList: res.item,
                nextUrl: res.next,
                totalCount: res.totalCount
            })
            this.props.onGetCount(res.totalCount);
        })
    }

    onUnFollow = async id => {
            const originalList =  this.state.followingList;
            const followingList = originalList.filter(m => m.user_id !== id);
            ToastsStore.success("UnFollow Successfully");

            this.setState({ followingList });

            try {
                await followUserService.remove(id);
            } catch (ex) {
                console.log(ex)
                if (ex.response && ex.response.status === 404)
                    ToastsStore.error("An unexpected error occurrred. Please Try Again.");


                this.setState({ followingList: originalList });
            }
    }

    nextPage = () => {
        // console.log("nextpage click");
        if(this.state.nextUrl) {
            this.setState({ loading: true });
            FollowingService.getList(this.state.nextUrl).then(res => {
                // console.log("nextpage click", res);
                this.setState({
                    followingList: this.state.followingList.concat(res.item),
                    nextUrl: res.next
                })
            })
            setTimeout(function() {
                this.setState({ loading: false });
            }.bind(this), 500)
        }
    }

    createList = () => {
        var list = [];
        if(this.state.followingList !== undefined && this.state.followingList.length > 0) {
            list = this.state.followingList.map((item, i) =>
                <li key={i}>
                    <Link to={(Cookies.get('usernameid') == item.username) ? "/profile" : "/user/"+item.username } >
                        <div className="avatar_profile d-flex align-items-center">
                            <img src={item.profile_image} alt='' className="img-fluid icon-image"/>
                            <h5>{item.name}</h5>
                        </div>
                    </Link>
                    {this.props.isUserProfile ? <button onClick={() => this.onUnFollow(item.user_id)} className="btn btn_default">UnFollow</button>: '' }
                    {/*<span className="case_rating"><img src="assets/images/briefcase_ic.png" alt='' className="img-fluid" /> <span className="rating">8.2</span></span>*/}
                </li>);
        }
        return list ;
    }

    getPageBtn = () => {
        return  (this.state.nextUrl) ? <a href="#!" onClick={() => this.nextPage(this.state.nextUrl)} className="ft_green_box">Show more</a>: '';
    }

    render() {
        const loading = this.state.loading;
        return (
            <div className="green_box follow_box mb-4 p-3">
                <h4>Following {this.state.totalCount === undefined ? 0 :this.state.totalCount}</h4>
                <div className="list_greenbox">
                    <ul>
                        {this.createList()}
                    </ul>
                </div>
                {loading ? <div className="img_container2"><i className="fa fa-spinner fa-spin spin1"></i></div> : ""}
                {this.getPageBtn()}
            </div>
        )
    }
}
