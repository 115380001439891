import React, { Component } from 'react'
import Globals from '../../Global/Constant';
import Cookies from 'js-cookie'
import ReactTimeAgo from 'react-time-ago'
import { connect } from "react-redux";
import API from '../../Global/PrivateAPI';
import { Link } from 'react-router-dom';
import { ToastsStore } from 'react-toasts';
import { load, LikePostToggle } from '../../store/reducer/post/action';
import InfiniteScroll from "react-infinite-scroll-component";
import postService from "../../services/postService";
import commentService, { postComments } from "../../services/commentService";
import ImageModel from "../Modal/ImageModel";
import LocationModel from "../Modal/locationModel";

class TimeLines extends Component {


  constructor(props) {
    super(props);
    this.toggleLike = this.toggleLike.bind(this);
    this.toggleCommentLike = this.toggleCommentLike.bind(this);
    this.saveComment = this.saveComment.bind(this);
    this.saveSubComment = this.saveSubComment.bind(this);
    this.GetCommentByEvent = this.GetCommentByEvent.bind(this);
    this.replyComment = this.replyComment.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.fetchMoreData = this.fetchMoreData.bind(this);
    let img = '';
    if (Cookies.get('user') !== undefined) { img = (JSON.parse(Cookies.get('user'))).profile_image; }
    this.state = {
      posts: [],
      IsLike: false,
      comment: "",
      ShowListCOmment: false,
      comments: [],
      sub_comment: '',
      currentPage: 1,
      content: <i className="fa fa-spinner fa-spin spin1"></i>,
      show: false,
      modelShow: null,
      locationModelShow: null,
      locationShow: false,
      userProfileImg: img
    }
  }


  async componentDidMount() {
    let { data } = await postService.getPublicPosts(1);
    this.props.loadPosts(data.data.item)
    this.setState({
      posts: data.data.item,
      pageCount: data.data._meta.pageCount,
      currentPage: this.state.currentPage + 1
    })
  }

  async fetchMoreData() {
    if (this.state.currentPage <= this.state.pageCount) {

      let { data } = await postService.getPublicPosts(this.state.currentPage);
      this.setState({
        posts: this.state.posts.concat(data.data.item),
        currentPage: this.state.currentPage + 1
      });

    } else {
      this.setState({ content: 'No More data' })
    }
  }
  deletePost(e) {
    if (window.confirm('Sure want to delete?')) {
      API.get(Globals.api + '/post/delete?id=' + e.currentTarget.dataset.id)
        .then(data => {

        })
    }
  }
  IncreaseComment(post_id) {
    for (var i = 0; i < this.state.posts.length; i++) {
      if (this.state.posts[i]['id'] == post_id) {
        this.state.posts[i].comment_count = this.state.posts[i].comment_count + 1;
      }
    }
  }
  DecreaseComment(post_id) {
    for (var i = 0; i < this.state.posts.length; i++) {
      if (this.state.posts[i]['id'] == post_id) {
        this.state.posts[i].comment_count = this.state.posts[i].comment_count - 1;
      }
    }
  }


  deleteComment(e) {
    e.preventDefault();
    var post_id = e.currentTarget.dataset.postId;
    console.log(post_id);
    if (window.confirm('Sure want to delete?')) {
      API.get(Globals.api + '/comment/delete?id=' + e.currentTarget.dataset.id)
        .then(data => {
          this.GetCommentByID(post_id);
          this.DecreaseComment(post_id);
          this.forceUpdate();
        })
    }
  }

  sendNotificationOnSocket = async (data) => {
    console.log('sendNotificationOnSocket');
    const socket = this.props.socket;
    console.log('socket', socket);
    if (data.user_id !== undefined) {
      socket.emit('new_notification', {
        text: data.notification.text,
        user_id: data.user_id,
        notification_type: data.notification.notification_type
      });
    }
  }

  async saveSubComment(e) {
    if (this.state.sub_comment != '') {
      var post_id = e.currentTarget.dataset.id;
      const formData = new FormData();
      formData.append('post_id', e.currentTarget.dataset.id);
      formData.append('text', this.state.sub_comment);
      formData.append('parent_id', e.currentTarget.dataset.commentId);

      try {
        let { data } = await commentService.postComments(formData);
        this.sendNotificationOnSocket(data.data);
        this.setState({
          sub_comment: '',
          currentCommentclick: false
        })
        ToastsStore.success("Comment submitted succesfully");
        this.IncreaseComment(post_id);
        this.GetCommentByID(post_id);
        this.forceUpdate();
      } catch (ex) {
        const errors = { ...this.state.errors };
        let res = ex.response.data.data
        console.log('errors', res)
        res.forEach(element => {
          errors[element.field] = element.message;
          ToastsStore.error(element.message);
        });
        this.setState({ errors, loading: false });
      }
    }
  }
  async saveComment(e) {
    if (this.state.comment != '') {
      var post_id = e.currentTarget.dataset.id;

      const formData = new FormData();
      formData.append('post_id', e.currentTarget.dataset.id);
      formData.append('text', this.state.comment);

      try {
        let { data } = await commentService.postComments(formData);
        this.sendNotificationOnSocket(data.data);
        this.setState({
          comment: ''
        })
        ToastsStore.success("Comment submitted succesfully");
        this.IncreaseComment(post_id);
        this.GetCommentByID(post_id);
        this.forceUpdate();
      } catch (ex) {
        const errors = { ...this.state.errors };
        let res = ex.response.data.data
        console.log('errors', res)
        res.forEach(element => {
          errors[element.field] = element.message;
          ToastsStore.error(element.message);
        });
        this.setState({ errors, loading: false });
      }
    }
  }
  GetCommentByID(id) {
    this.CallCommentAPI(id);
  }
  GetCommentByEvent(e) {
    e.preventDefault();
    var post_id = e.currentTarget.dataset.id;
    this.CallCommentAPI(post_id);
  }
  replyComment(e) {
    e.preventDefault();
    this.setState({
      currentCommentclick: e.currentTarget.dataset.id
    })
  }

  async CallCommentAPI(post_id) {
    let { data } = await commentService.getComments(post_id)

    var key = "comment_" + post_id
    var val = data.data.item
    var obj = {}
    obj[key] = [val]

    this.setState({
      ...obj
    })
  }
  toggleLike(e) {
    e.preventDefault();
    for (var i = 0; i < this.state.posts.length; i++) {
      if (this.state.posts[i]['id'] == e.currentTarget.dataset.id) {
        //this.props.LikePostToggle(i)

        let value = this.state.posts[i].is_like
        this.state.posts[i].is_like = !value;

        API.get(Globals.api + '/like/like?post_id=' + e.currentTarget.dataset.id)
          .then(data => {

          })

        if (this.state.posts[i].is_like) {
          this.state.posts[i].like_count = parseInt(this.state.posts[i].like_count) + 1
        }
        else {
          this.state.posts[i].like_count = parseInt(this.state.posts[i].like_count) - 1
        }

        this.forceUpdate()
      }
    }

  }

  toggleCommentLike(e) {
    e.preventDefault();
    var key = "comment_" + e.currentTarget.dataset.id
    // if ( this.state.key == e.currentTarget.dataset.id) {
    //this.props.LikePostToggle(i)

    let value = this.state[key][0][e.currentTarget.dataset.index].is_like
    this.state[key][0][e.currentTarget.dataset.index].is_like = !value;
    var comment_id = (e.currentTarget.dataset.commentid) ? e.currentTarget.dataset.commentid : null;
    API.get(Globals.api + '/like/like?post_id=' + e.currentTarget.dataset.id + '&comment_id=' + comment_id)
      .then(data => {

      })

    if (this.state[key][0][e.currentTarget.dataset.index].is_like) {
      this.state[key][0][e.currentTarget.dataset.index].like_count = parseInt(this.state[key][0][e.currentTarget.dataset.index].like_count) + 1
    }
    else {
      this.state[key][0][e.currentTarget.dataset.index].like_count = parseInt(this.state[key][0][e.currentTarget.dataset.index].like_count) - 1
    }

    this.forceUpdate()
    // }
  }
  showModelPop = (item) => {
    this.setState({ modelShow: item });
    this.setState({ show: true });
  }

  hideModelPop = () => {
    this.setState({ show: false });
  }
  
  showLocationModelPop = (item) => {
    this.setState({ locationModelShow: item });
    this.setState({ locationShow: true });
  }

  hideLocationModelPop = () => {
    this.setState({ locationShow: false });
  }

      timeSince(date) {

      var seconds = Math.floor((new Date() - date) / 1000);

      var interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " years ago";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " months ago";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " days ago";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hours ago";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes ago";
      }
      return Math.floor(seconds) + " seconds ago";
    }

  convertUTCDateToLocalDate = (date) => {
    date = new Date(date);
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return this.timeSince(newDate);
  }

  render() {
    return (
      <InfiniteScroll
        dataLength={this.state.posts.length}
        next={this.fetchMoreData}
        hasMore={true}
        loader={<div className="img_container2">{this.state.content}</div>}>
        {(this.state.show) ? <ImageModel hideModelPop={this.hideModelPop} mdata={this.state.modelShow} /> : ''}
        {(this.state.locationShow) ? <LocationModel hideLocationModelPop={this.hideLocationModelPop} mdata={this.state.locationModelShow} /> : ''}
        {this.state.posts.map((post,i) => {
          return (
            <div className="timeline_content" key={post.id}>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="veen" role="tabpanel" aria-labelledby="veen-tab">
                  <div className="timeline_box">
                    <div className="box_inner d-flex align-items-start">
                      <Link to={(Cookies.get('usernameid') == post.username) ? "/profile" : "/user/" + post.username}>
                        <img src={post.user_profile_image} alt='' className="img_circle" />
                      </Link>
                      <div className="box_inner_content">
                        <div className="name_div">

                          <h4 className="d-inline-block"><a style={{ color: 'black' }}
                            href={(Cookies.get('usernameid') == post.username) ? "/profile" : "/user/" + post.username}>{post.full_name} </a><span>{post.activity} at</span>
                          </h4> 
                          <span>                            
                            <Link to={"/place/" + post.place_id}>
                              {post.location}
                            </Link>
                            <a href="javascript:void(0)" onClick={() => this.showLocationModelPop(post)}  >
                               <img src="assets/images/googlemaps.png" alt="logo" className="googleLogo" style={{width: "18px !important"}} />                              
                          </a>
                          </span>
                          
                          {(Cookies.get('usernameid') == post.username) ?
                            <div className="dropdown" >
                              
                              <a href="#" className="drop_angle" id="dropdownMenuButton" data-toggle="dropdown" 
                                aria-haspopup="true" aria-expanded="false">
                                  <i className="fas fa-angle-down"></i>
                              </a>
                              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <Link onClick={this.deletePost} data-id={post.id} className="dropdown-item"
                                  to="/">Delete</Link>
                              </div>
                            </div> : ''}
                        </div>
                        <div className="middle_box">
                          <p>{(post.body == 'undefined') ? '' : post.body} </p>
                        </div>
                        <div className="ft_box">

                          <div className="row">
                            {
                              post.files.map((images, i) => {
                                if (post.files.length) {
                                  return <div key={i}
                                    className={(post.files.length <= 2) ? 'col-md-' + (12 / post.files.length) : 'col-md-4'}>
                                    <div className="">
                                      <img src={images.filepath} className="img-fluid" style={{ cursor: 'pointer' }}  onClick={() => this.showModelPop({ post, indexId: i })}/></div>
                                  </div>
                                }
                              })
                            }
                            {/* {
                              (post.files.length > 0) ?
                              <div className="ft_img">
                              <a href="javascript:void(0)" class="viewMoreOverlay" onClick={() => this.showModelPop({ post, indexId: i })}>View More</a>
                              </div> : ""
                            } */}
                          </div>
                          
                          <div className="ft_inner_ft">
                            <ul>
                              <li>
                                <Link to="/" onClick={this.toggleLike} data-id={post.id}><i
                                  className={(post.is_like) ? "fas fa-thumbs-up" : "fas fa-thumbs-up grey"}></i>
                                  <span>{post.like_count}</span></Link>
                              </li>
                              <li>
                                <Link to="/" data-id={post.id} onClick={this.GetCommentByEvent}><i
                                  className="fas fa-comment-alt"></i> <span>{post.comment_count}</span></Link>
                              </li>

                            </ul>
                            <span className="hour_class">{post.created !== undefined ? this.convertUTCDateToLocalDate(post.created) : ''}</span>
                          </div>
                        </div>
                        <div className="comment-box-post">
                          <ul>
                            <li>
                              <img src={this.state.userProfileImg} alt=""
                                className='img-fluid img_circle m-lg-n1' />
                            </li>
                            <li className="center">
                              <input type="text" className="form-control" value={this.state.comment}
                                onChange={e => this.setState({ comment: e.target.value })}
                                placeholder="Add a comment..." />
                            </li>
                            <li>
                              <button data-id={post.id} onClick={this.saveComment} className="btn">Send</button>
                            </li>
                          </ul>
                        </div>

                        <div className="main-comment-sections">
                          <div className="comment-box-inner mt-2 d-block cstm">
                            {(this.state["comment_" + post.id]) ? this.state["comment_" + post.id][0].map((comment, i) => {
                              return (
                                <div className="comment-box-post d-block mb-1" key={i}>
                                  <ul>
                                    <li>
                                      <img src={comment.user_profile_image}
                                        className="img-fluid img_circle m-lg-n1" alt="" />
                                    </li>
                                    <li>
                                      <h6>{comment.user_name}</h6>
                                      <p>{comment.text}.</p>
                                      <Link to="/" data-id={comment.id} onClick={this.replyComment}
                                        className="cstm-chat this">Reply <i
                                          className="fas fa-reply cstm-chat this"></i></Link>
                                    </li>
                                  </ul>
                                  <div className="dropdown">
                                    <Link to="/" onClick={this.toggleCommentLike} data-id={post.id} data-index={i} data-commentid={comment.id}><i
                                      className={(comment.is_like) ? "fas fa-thumbs-up" : "fas fa-thumbs-up grey"}></i>
                                      <span>{(comment.like_count) ? comment.like_count : null}</span></Link>
                                              &nbsp;
                                              <a href="#" className="drop_angle" id="dropdownMenuButton"
                                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                        className="fas fa-angle-down"></i></a>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                      <Link onClick={this.deleteComment} data-id={comment.id}
                                        data-post-id={post.id} className="dropdown-item"
                                        to="/">Delete</Link>
                                    </div>
                                  </div>
                                  <div
                                    className={(this.state.currentCommentclick == comment.id) ? "comment-box-post d-block" : "d-none"}>
                                    <ul>
                                      <li>
                                        <img src={this.state.userProfileImg}
                                          className='img-fluid img_circle m-lg-n1' alt="" />
                                      </li>
                                      <li className="center">
                                        <input type="text" className="form-control"
                                          value={this.state.sub_comment}
                                          onChange={e => this.setState({ sub_comment: e.target.value })}
                                          placeholder="Add a comment..." />
                                      </li>
                                      <li>
                                        <button data-id={post.id} data-comment-id={comment.id}
                                          onClick={this.saveSubComment} className="btn">Send
                                                  </button>
                                      </li>
                                    </ul>
                                  </div>
                                  {

                                    (comment.comments) ? comment.comments.map((innercomment, i) => {
                                      return (
                                        <div className="comment-box-post d-block mb-1 sub" key={i}>
                                          <ul>

                                            <div>
                                              <li>
                                                <img src={innercomment.user_profile_image}
                                                  className='img-fluid img_circle m-lg-n1 ' alt="" />
                                              </li>
                                              <li>
                                                <h6>{innercomment.user_name}</h6>
                                                <p>{innercomment.text}</p>

                                              </li>
                                            </div>
                                          </ul>
                                        </div>
                                      )
                                    }) : ""
                                  }
                                </div>
                              )

                            }) : ""


                            }

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        )
        }
      </InfiniteScroll>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    data: state,
    socket: state.socket.socket,
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadPosts: posts => dispatch(load(posts)),
    LikePostToggle: id => dispatch(LikePostToggle(id)),
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(TimeLines);