import API from "../Global/PrivateAPI";
import Globals from "../Global/Constant";
import QueryString from "query-string"

const gallery = {
    getList: function(url){
        return API.get( (url) ? url : Globals.api + '/gallery').then(data => {
            return {
                item: (data.data.item !== undefined && data.data.item.length > 0) ? data.data.item : [],
                next: (data.data._links.next === undefined) ? null : data.data._links.next.href
            };
        });
    },

    getUserGalleryList: function (url,obj) {
        const stringified = QueryString.stringify(obj);


        return API.get((url) ? url :(Globals.api + '/gallery?' + stringified)).then(data => {
            return {
                item: (data.data.item !== undefined && data.data.item.length > 0) ? data.data.item : [],
                next: (data.data._links.next === undefined) ? null : data.data._links.next.href,
                meta: data.data._meta
            };
        });
    }
}

export default gallery;