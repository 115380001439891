import React, { Component } from 'react'
import { connect } from "react-redux";
import { PROFILE_LOAD } from '../../store/reducer/Profile/type';
import Map from '../../component/Map/Map'
import Header from '../../component/Header';
import Footer from '../../component/Footer/index'
import Cookies from 'js-cookie'
import Follower from '../../component/Follow/follower';
import PlaceProfileTimeline from '../../component/PlaceProfileTimeline';
import placeProfile from "../../services/placeProfileService";
import { Latitude, Longitude, IsMapLoaded, CursorPositionChange, typeofLocation, placeIDChange } from '../../store/reducer/Map/action';
import Geocode from "react-geocode";
import Globals from "../../Global/Constant";
import StarRatings from 'react-star-ratings';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import ReactTimeAgo from 'react-time-ago'

class PlaceProfile extends Component {

  state = {
    place: '',
    showModal: false,
    rating: 0,
    review: '',
    errorMsg: '',
  }

  constructor(props) {
    super(props);
    Cookies.get('accessToken') == null ? this.props.history.push('/') : this.props.history.push(this.props.location.pathname);
    this.getPlaceProfile();
  }

  timeSince(date) {

  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

  convertUTCDateToLocalDate = (date) => {
    date = new Date(date);
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return this.timeSince(newDate);
  }

  

  getAddressfromLatlng(lat, lng) {
    Geocode.setLanguage('en')
    Geocode.setApiKey(Globals.mapApi)

    Geocode.fromLatLng(lat, lng).then(
      response => {
        const address = response.results[0].formatted_address;
        this.props.ChangeMapLocation(address)
      },
      error => {
        return error;
      }
    );
  }
  getMyLocation(isCustom = false) {
    Geocode.setLanguage('en')
    Geocode.setApiKey(Globals.mapApi)
    const location = window.navigator && window.navigator.geolocation



    if (location) {
      location.getCurrentPosition((position) => {
        console.log('position', position);
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
          response => {
            this.props.typeofLocation(response.results[0]['types']);
            this.props.placeIDChange(response.results[0]['place_id']);
            // this.setStateData(response.results[0]['address_components'], position.coords.latitude, position.coords.longitude)
            this.props.latitudeChange(position.coords.latitude)
            this.props.longitudeChange(position.coords.longitude)
            this.getAddressfromLatlng(position.coords.latitude, position.coords.longitude)
            this.props.isMapLoaded('true')
          },
          error => {
            console.log("Geocode.fromLatLng error", error);
          }
        )
      }, (error) => {
        console.log("getCurrentPosition error", error);
        this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
      })
    }

  }

  async getPlaceProfile() {
    var placeId = (this.props.location.pathname).split('/')[2];
    await placeProfile.getdetail(placeId).then(data => {
      if (!data.data) {
        this.props.history.replace('/home');
      }
      this.setState({
        place: data.data
      })
    });
  }

  componentDidMount() {
    this.props.action('none');
    this.getMyLocation();
  }

  handleFollower = (count) => {
    this.setState({ followerCount: count });
  }

  showModal = () => {
    this.setState({ showModal: true })
  }

  hideModal = () => {
    this.setState({ showModal: false })
  }

  changeRating = (newRating) => {
    this.setState({
      rating: newRating
    });
  }

  onReviewChange = (event) => {
    this.setState({
      review: event.target.value
    });
  }

  reviewSubmit = () => {
    this.setState({ errorMsg: '' })
    if (this.state.rating == 0) {
      this.setState({ errorMsg: 'Please Select Rating' })
      return
    } else if (this.state.review == '') {
      this.setState({ errorMsg: 'Please Enter Review' })
      return
    }


    const formData = new FormData();
    formData.append('place_id', this.state.place.id);
    formData.append('rating', this.state.rating);
    formData.append('review', this.state.review);

    fetch(Globals.api + '/place/post-review', {
      'method': 'post',
      headers: new Headers({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + Cookies.get('accessToken')
      }),
      body: formData
    }).then(res => res.json()).then((response) => {
      if (response.statusCode != 200) {
        this.setState({ errorMsg: response.message, loading: false })
      } else {
        window.location.reload();
      }
    })
  }

   _mapLoaded(mapProps, map) {
            map.setOptions({
                styles: mapStyle
            })
        }
        
  render() {

    return (
      <div className='col-md-12'>
        <Header />
        <section className="wrapper_sec bg_grey">
          <ToastsContainer position={ToastsContainerPosition.TOP_RIGHT} store={ToastsStore} />

          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="map_area">
                  {(this.state.place.id) ? <Map placeId={this.state.place.id} zoom={2} focusCenter={true} onReady={(mapProps, map) => this._mapLoaded(mapProps, map)} /> : ''}
                </div>
                <div className="profile_divwrap">
                  <div className="row">
                    <div className="col-md-10 col-12">
                      <div className="wrap_profile_detail">
                        <div className="name_div same_wrap">
                          <h1>{this.state.place.location}</h1>
                          <div className="follower_div">
                            <span><span className="clr_seagreen">{this.state.place ? this.state.place.post_count : 0}</span> Posts</span>
                            <span>|</span>
                            <span>{this.state.place ? this.state.place.reviews.length : 0} Reviews</span>
                          </div>
                        </div>
                      </div>
                      {/* <div className="wrap_profile_detail d-flex align-items-center flex-wrap">
                        <div className="name_div same_wrap">
                          <h1>{this.state.place.location}</h1>
                          <div className="follower_div">

                            <span>{(this.state.followerCount != '') ? this.state.followerCount : '-'} Followers</span>
                          </div>
                        </div>
                        <div className="interest_div same_wrap">
                          <h4>Interset</h4>
                          <p>Fishing, Traveling, Spending time with the family</p>
                        </div>
                        <div className="info_div same_wrap">
                          <h4>Info</h4>
                          <p>Fishing, Traveling, Spending time with the family</p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrap_timeline mt-5 pt-3">
              <div className="row">
                <div className="col-lg-4 col-md-5 col-12">
                  {/*<div className="timeline_photos">*/}
                  {/*<div className="photos_head d-flex align-items-center justify-content-between flex-wrap pt-2 pb-3 px-1">*/}
                  {/*<h4>Photos</h4>*/}
                  {/*<p>132 Pics</p>*/}
                  {/*</div>*/}
                  {/*<div className="d-flex flex-wrap">*/}
                  {/*<a href="#"><img src="/assets/images/timeline_pic1.jpg" className="img-fluid" /></a>*/}
                  {/*<a href="#"><img src="/assets/images/timeline_pic2.jpg" className="img-fluid" /></a>*/}
                  {/*<a href="#"><img src="/assets/images/timeline_pic3.jpg" className="img-fluid" /></a>*/}
                  {/*<a href="#"><img src="/assets/images/timeline_pic4.jpg" className="img-fluid" /></a>*/}

                  {/*</div>*/}
                  {/*</div>*/}
                  {/* {(this.state.place.id) ?  */}
                  {/* <Follower placeId={this.state.place.id} onGetCount={this.handleFollower}/> : '' } */}
                  <div className="timeline_photos">
                    <div className="photos_head d-flex align-items-center justify-content-between flex-wrap pt-2 pb-3 px-1">
                      <h4>Reviews</h4>
                      {!this.state.place.is_review_posted && <a href="#" className="rvwBtn" onClick={this.showModal}>Add a Review</a>}
                    </div>

                    {(this.state.place.id) && this.state.place.reviews.map((review) => {
                      return (
                        <div className="reviewMain">
                          <div className="clientDetails">
                            <figure className="m-0">
                              <img style={{ width: '55px' }} src={review.profile_image} className="img-fldui" />
                            </figure>
                            <p>{review.fname + ' ' + review.lname}</p>
                            <div className="ratings">
                              <StarRatings rating={review.rating}
                                starRatedColor='#4aa19f'
                                numberOfStars={5}
                                name='rating'
                                starDimension="13px"
                                isSelectable={false}

                              />
                              {/* <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i> */}
                            </div>
                          </div>
                          <div className="clientReview">
                            <p><i>{review.review}</i></p>
                          </div>
                          <div className="timeStamp">
                            <p>
                              {this.convertUTCDateToLocalDate(review.created)}

                            </p>
                          </div>
                        </div>)
                    })}
                  </div>
                  <Footer />
                </div>
                <div className="col-lg-8 col-md-7 col-12">
                  <div className="wrap_timeline_post">
                    <div className="unlimited_posts bg_white">
                      {(this.state.place.id) ? <PlaceProfileTimeline placeId={this.state.place.id} /> : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={this.state.showModal ? 'modal display-block' : 'modal display-none'}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="reviewModalLabel">Add a Review</h5>
                <button type="button" className="close" onClick={this.hideModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">

                <form>
                  <ul className="ratings">
                    <div className="reviewStars" >
                      <span className="black-text mr-2">How do you rate this place?</span>

                      <StarRatings
                        rating={this.state.rating}
                        starRatedColor='rgb(230, 67, 47)'
                        changeRating={this.changeRating}
                        numberOfStars={5}
                        name='rating'
                        starDimension="25px"

                      />
                      {/* <li><a><i className="fa-star fa-sm text-primary review-rating fas" data-index="1"></i></a></li>
                      <li><a><i className="fa-star fa-sm text-primary review-rating far" data-index="2"></i></a></li>
                      <li><a><i className="fa-star fa-sm text-primary review-rating far" data-index="3"></i></a></li>
                      <li><a><i className="fa-star fa-sm text-primary review-rating far" data-index="4"></i></a></li>
                      <li><a><i className="fa-star fa-sm text-primary review-rating far" data-index="5"></i></a></li> */}
                    </div>
                    <div className="md-form md-outline mt-3 mb-3">
                      <label for="mdb_review_content">Your review</label>
                      <textarea id="mdb_review_content" name="mdb_review_content" className="md-textarea form-control" rows="4" required="" value={this.state.review} onChange={this.onReviewChange}></textarea>
                    </div>
                    <div className="error-msg">{this.state.errorMsg}</div>
                  </ul>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-theme" onClick={this.reviewSubmit}>Submit</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

const mapStyle = [
        {
            featureType: 'landscape.natural.landcover',
            elementType: 'geometry.fill',
            stylers: [
                {
                    "visibility": "on"
                },
                {                     
                    color: '#6fb257'            
                }
            ]
        },
        {
            featureType: 'transit.line',
            elementType: 'geometry.fill',
            stylers: [
                {
                    "visibility": "on"
                },
                {                
                    color: '#d7f443'                
                }
            ]
        },
        {
            featureType: 'landscape',
            elementType: 'geometry.fill',
            stylers: [
                 {
                    "visibility": "on"
                },
                {   
                    color: '#9dc8a4'                
                }
            ]
        },
        {
            featureType: 'road',
            elementType: 'geometry.fill',
            stylers: [
                {
                 "visibility": "on"
                },
                {   
                    color: '#dff443'                
                }
            ]
        },
        {
            featureType: 'road.local',
            elementType: 'geometry.fill',
            stylers: [
                {
                 "visibility": "off"
                },
                {   
                    color: '#dff443'                
                }
            ]
        },
    ]
const mapDispatchToProps = (dispatch) => {
  return {
    action: (display) => dispatch({
      type: PROFILE_LOAD,
      display: display
    }),
    ChangeMapLocation: description => dispatch(CursorPositionChange(description)),
    latitudeChange: latitude => dispatch(Latitude(latitude)),
    typeofLocation: type => dispatch(typeofLocation(type)),
    placeIDChange: id => dispatch(placeIDChange(id)),
    longitudeChange: longitude => dispatch(Longitude(longitude)),
    isMapLoaded: IsMap => dispatch(IsMapLoaded(IsMap)),
  }
};

export default connect(null, mapDispatchToProps)(PlaceProfile)

