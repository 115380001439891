import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL ;

export async function getLatestNotificationService() {
  const {data} = await http.get(apiEndpoint+ "/notification/latest-notification", {});
  return data.data;
}

export default {getLatestNotificationService};
