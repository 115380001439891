import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/comment/";



export function getComments(post_id) {
    return http.get(apiEndpoint+'get-comment', {params: {post_id: post_id}});
}

export function postComments(post) {
    return http.post(apiEndpoint+'create', post);
}


export default {
    getComments,
    postComments
};


