import { SOCKET_ID, NEW_MESSAGE_LIST, NEW_NOTIFICATION_LIST} from './type'

export default (state = [], action) => {

    switch (action.type) {
        case SOCKET_ID:
            return { ...state, socket: action.socket };
        case NEW_MESSAGE_LIST:
            return { ...state, new_message_list: action.new_message_list };
        case NEW_NOTIFICATION_LIST:
            return { ...state, new_notification_list: action.new_notification_list };
        default:
            return state;
    }
}
