import React, { Component } from 'react'
import { connect } from "react-redux";
import { PROFILE_LOAD } from '../../store/reducer/Profile/type';
import Map from '../../component/Map/Map'
import Header from '../../component/Header';
import Footer from '../../component/Footer/index'
import Cookies from 'js-cookie'
import Follower from '../../component/Follow/follower' ;
import Following from '../../component/Follow/following' ;
import ProfileTimeline from '../../component/ProfileTimeline/TimeLine'
import Modal from "../../component/Modal";
import {HIDE_MODAL, SHOW_MODAL} from "../../store/reducer/Modal/type";
import Avatar from "../../component/Avatar/index"
import {Link} from "react-router-dom";
import profile from "../../services/profileService";
import UserGallery from "../../component/Gallery/UserGallery";
import userInfo from "../../services/userInfoUpdateService";
import { Latitude, Longitude, IsMapLoaded, CursorPositionChange, typeofLocation, placeIDChange } from '../../store/reducer/Map/action';
import Geocode from "react-geocode";
import Globals from "../../Global/Constant";
class Profile extends Component {

    state = {
        user: '',
        editableInterset: false,
        editableInfo: false,
        infoOriginalText: null,
        infoText: null,
        intersetOriginalText: null,
        intersetText: null
    }

  constructor(props) {
    super(props);
    Cookies.get('accessToken') == null ? this.props.history.push('/') : this.props.history.push('/profile');
  }

  async componentDidMount() {
        await profile.getdetail().then(data => {
          this.setState({
              user: data.data,
              infoOriginalText: data.data.info,
              infoText: data.data.info,
              intersetOriginalText: data.data.interset,
              intersetText: data.data.interset
          })
        })
    this.props.action('none');
        this.getMyLocation();
  }
    getAddressfromLatlng(lat, lng) {
        Geocode.setLanguage('en')
        Geocode.setApiKey(Globals.mapApi)

        Geocode.fromLatLng(lat, lng).then(
            response => {
                const address = response.results[0].formatted_address;
                this.props.ChangeMapLocation(address)
            },
            error => {
                return error;
            }
        );
    }
  getMyLocation(isCustom = false) {
        Geocode.setLanguage('en')
        Geocode.setApiKey(Globals.mapApi)
        const location = window.navigator && window.navigator.geolocation



        if (location) {
            location.getCurrentPosition((position) => {
                console.log('position',position);
                Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                    response => {
                        this.props.typeofLocation(response.results[0]['types']);
                        this.props.placeIDChange(response.results[0]['place_id']);
                        // this.setStateData(response.results[0]['address_components'], position.coords.latitude, position.coords.longitude)
                        this.props.latitudeChange(position.coords.latitude)
                        this.props.longitudeChange(position.coords.longitude)
                        this.getAddressfromLatlng(position.coords.latitude,position.coords.longitude)
                        this.props.isMapLoaded('true')
                    },
                    error => {
                        console.log("Geocode.fromLatLng error",error);
                    }
                )
            }, (error) => {
                console.log("getCurrentPosition error",error);
                this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
            })
        }

    }

  showModal = () =>{
    this.props.showModalClick('block');
  }
  hideModal = () => {
        this.props.HideModalClick('none');
    };

  handleFollowing = (count) => {
    this.setState({followingCount: count});
  }

  handleFollower = (count) => {
    this.setState({followerCount: count});
  }

  interseTextChange = (e) => {
      // console.log("interset", e.target.value, e.target.value.length);
      let tempText = e.target.value.length > 120 ?this.state.intersetText:e.target.value;
      this.setState({intersetText: tempText});
  }
  showIntersetEdit = () => {
        this.setState({editableInterset: true});
  }
  hideIntersetEdit = () => {
        this.setState({editableInterset: false, intersetText: this.state.intersetOriginalText});
  }
  saveIntersetEdit = async () => {
      // console.log("saveInterset click", this.state.intersetText);
      const data = await userInfo.updateUserInfo(null, this.state.intersetText);
      // console.log(data);
      var interset = (data.interset) ? data.interset : this.state.intersetOriginalText;
      this.setState({editableInterset: false, intersetText: interset, intersetOriginalText: interset});
  }

  infoTextChange = (e) => {
      // console.log("interset", e.target.value);
      let tempText = e.target.value.length > 120 ? this.state.intersetText:e.target.value;
      this.setState({infoText: tempText});
  }
  showInfoEdit = () => {
        this.setState({editableInfo: true});
  }
  hideInfoEdit = () => {
        this.setState({editableInfo: false, infoText: this.state.infoOriginalText});
  }
  saveInfoEdit = async () => {
      // console.log("saveinfo click", this.state.infoText);
      const data = await userInfo.updateUserInfo(this.state.infoText,null);
      // console.log(data);
      var info = (data.info) ? data.info : this.state.infoOriginalText;
      this.setState({editableInfo: false, infoText: info, infoOriginalText: info});
  }

  render() {
    console.log('testtest',this.state.user.id)
    // var avatars = "";
    // if (this.state.user != '') {
    //  var avatars = <Avatar src={this.state.user.profile_image_original}></Avatar>
    // }

    return (


      <div className='col-md-12'>
        <Modal>
            <div id="msform"  className={'col-md-12'}>
                <strong>Update Profile Picture</strong>
                <Link className={'float-right'} style={this.style} to="/profile" onClick={this.hideModal}><i className="fas fa-times-circle"></i></Link>
                <br />
              {(this.state.user != '') ? <Avatar src={this.state.user.profile_image_original}></Avatar> : null}

            </div>
        </Modal>
        <Header />
        <section className="wrapper_sec bg_grey">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="map_area">
                  <Map zoom={2}  focusCenter={true} />
                </div>
                <div className="profile_divwrap">
                  <div className="row">
                    <div className="col-xl-2 col-lg-2 col-md-3 col-12 p-0 text-center-xs">
                      <div className="pro_img_post">
                        <a href={"#!"} onClick={this.showModal} >
                          <img src={this.state.user.profile_image} alt='' className="img-fluid profile_img" />
                        </a>
                      </div>
                    </div>
                    <div className="col-md-10 col-12">
                      <div className="wrap_profile_detail d-flex align-items-center flex-wrap">
                        <div className="name_div same_wrap">
                          <h1>{this.state.user.fname + " " + this.state.user.lname}</h1>
                          <div className="follower_div">
                            <span><span className="clr_seagreen"> {(this.state.followingCount != '') ? this.state.followingCount : '-' }</span> Following</span>
                            <span>|</span>
                            <span>{(this.state.followerCount != '') ? this.state.followerCount : '-' } Followers</span>
                          </div>
                        </div>

                        <div className="interest_div same_wrap">
                          <h4>Interest</h4>
                            <span>
                               {this.state.editableInterset == false ?
                                   <i onClick={this.showIntersetEdit} style={{ float : 'right', cursor : 'pointer'}} className="fas fa-edit"></i>:
                                   <><i onClick={this.saveIntersetEdit} style={{ float : 'right', marginLeft: '7px', cursor : 'pointer'}} className="fas fa-save"></i>
                                       <i onClick={this.hideIntersetEdit} style={{ float : 'right', cursor : 'pointer'}} className="fas fa-times"></i></>}
                            </span>
                          {this.state.editableInterset == false ? <p>{this.state.intersetText}</p> :
                            <textarea name='inputInterset' onChange={event => this.interseTextChange(event)} value={this.state.intersetText}/> }
                        </div>
                        <div className="info_div same_wrap">
                          <h4>Info</h4>
                            <span>
                                {this.state.editableInfo == false ?
                                <i onClick={this.showInfoEdit} style={{ float : 'right', cursor : 'pointer'}} className="fas fa-edit"></i>:
                                <><i onClick={this.saveInfoEdit} style={{ float : 'right', marginLeft: '7px', cursor : 'pointer'}} className="fas fa-save"></i>
                                  <i onClick={this.hideInfoEdit} style={{ float : 'right', cursor : 'pointer'}} className="fas fa-times"></i></>}
                            </span>
                            {this.state.editableInfo == false ? <p>{this.state.infoText}</p> :
                          <textarea name='inputInfo' onChange={event => this.infoTextChange(event)} value={this.state.infoText}/> }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrap_timeline mt-5 pt-3">
              <div className="row">
                <div className="col-lg-4 col-md-5 col-12">
                 <UserGallery/>
                  {this.state.user && <Follower userId={this.state.user.id} onGetCount={this.handleFollower}/> }
                  {this.state.user && <Following userId={this.state.user.id} onGetCount={this.handleFollowing} isUserProfile={true}/> }
                  <Footer />
                </div>
                <div className="col-lg-8 col-md-7 col-12">
                  <div className="wrap_timeline_post">
                    {/*<div className="post_div">*/}
                    {/*  <div className="post_area">*/}
                    {/*    <img src="assets/images/post_profile_img.png" className="img-fluid" />*/}
                    {/*    <textarea className="form-control" placeholder="Click here to Veen your current location and update your status?"></textarea>*/}
                    {/*  </div>*/}
                    {/*  <div className="ft_post d-flex align-items-center justify-content-end">*/}
                    {/*    <a href="#" className="ml-3"><img src="assets/images/post_ic_tag.png" className="img-fluid" /></a>*/}
                    {/*    <a href="#" className="ml-3"><img src="assets/images/post_ic_cam.png" className="img-fluid" /></a>*/}
                    {/*    <a href="#" className="ml-3"><img src="assets/images/post_ic_attach.png" className="img-fluid" /></a>*/}
                    {/*    <a href="#" className="ml-3"><img src="assets/images/post_ic_smily.png" className="img-fluid" /></a>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div className="unlimited_posts bg_white">
                      <ProfileTimeline />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    action: (display) => dispatch({
      type: PROFILE_LOAD,
      display: display
    }),
      showModalClick :  (display) => dispatch({
      type: SHOW_MODAL,
      displayModal: display
    }),
      HideModalClick: (display) => dispatch({
          type: HIDE_MODAL,
          displayModal: display
      }),
      ChangeMapLocation: description => dispatch(CursorPositionChange(description)),
      latitudeChange : latitude => dispatch(Latitude(latitude)),
      typeofLocation : type => dispatch(typeofLocation(type)),
      placeIDChange :  id =>  dispatch(placeIDChange(id)),
      longitudeChange : longitude => dispatch(Longitude(longitude)),
      isMapLoaded : IsMap => dispatch(IsMapLoaded(IsMap)),
  }
};

export default connect(null, mapDispatchToProps)(Profile)

