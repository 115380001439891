import React, { Component } from 'react'
import gallery from "../../services/galleryService";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from 'react-router-dom';
import GalleryModel from "../Gallery/GalleryModel";

import Cookies from "js-cookie";

export default class Gallery extends Component {

    state = {
        list: [],
        nextUrl: null,
        
        modelShow:[],
        show: false,
    }

    componentDidMount() {
        gallery.getList().then(res => {
            this.setState({
                list: res.item,
                nextUrl: res.next
            })
        })
    }

    nextPage = () => {
        if (this.state.nextUrl) {
            gallery.getList(this.state.nextUrl.replace("/v1", "/index.php/v1")).then(res => {
                this.setState({
                    list: this.state.list.concat(res.item),
                    nextUrl: res.next
                })
            })
        }
    }

    onMapMarkerClick = (item) => {
        console.log('Onclick onMapMarkerClick called', item);
        gallery.getUserGalleryList(null, { user_id: item.user_id, post_id: item.post_id }).then(res => {
            this.setState({
                modelShow: res.item[0],
                show: true,
            })
        })
    }

    createList = () => {
        var list = [];
        if (this.state.list !== undefined && this.state.list.length > 0) {
            list = this.state.list.map((item, i) =>
                <div key={i} className="pl-1 col-md-4 pr-1">
                    <div className="gellery_box" onClick={() => this.onMapMarkerClick(item)} style={{cursor: 'pointer'}}>
                        {/* <Link to={(Cookies.get('usernameid') == item.username) ? "/profile" : "/user/" + item.username}> */}
                        <img src={item.filepath} alt="" style={{ width: '100%' }} />
                        <span className="gallery-post">
                            Posted by <img src={item.user_profile_image} alt={item.username} title={item.username}
                                className="img_circle rounded-circle" />
                        </span>
                        {/* </Link> */}
                    </div>
                </div>
            );
        }
        return list;
    }

    hideModelPop = () => {
        this.setState({ show: false });
    }

    render() {
        var hasMore = (this.state.nextUrl) ? true : false;
        return (
            <React.Fragment>
                <InfiniteScroll
                    dataLength={this.state.list.length}
                    next={this.nextPage}
                    hasMore={hasMore}
                    loader={<div className="img_container2"><i className="fa fa-spinner fa-spin spin1"></i></div>}
                >
                    <div className="timeline_content">
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade gellery-section active show" id="gallery" role="tabpanel"
                                aria-labelledby="gallery-tab">
                                <div className="row">
                                    {this.createList()}
                                </div>
                            </div>
                        </div>
                    </div>
                </InfiniteScroll>
                {(this.state.show) ? <GalleryModel hideModelPop={this.hideModelPop} mdata={this.state.modelShow} /> : ''}

            </React.Fragment>

        )
    }
}
