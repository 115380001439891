import React, { Component } from 'react'
import { connect } from "react-redux";
import { PROFILE_LOAD } from '../../store/reducer/Profile/type';
import Map from '../../component/Map/Map'
import Header from '../../component/Header';
import Footer from '../../component/Footer/index'
import Cookies from 'js-cookie'
import Follower from '../../component/Follow/follower';
import Following from '../../component/Follow/following';
import UserProfileTimeline from '../../component/UserProfileTimeline'
import Modal from "../../component/Modal";
import { HIDE_MODAL, SHOW_MODAL } from "../../store/reducer/Modal/type";
import Avatar from "../../component/Avatar/index"
import { Link } from "react-router-dom";
import userProfile from "../../services/userProfileService";
import UserGallery from "../../component/Gallery/UserGallery";
import { Latitude, Longitude, IsMapLoaded, CursorPositionChange, typeofLocation, placeIDChange } from '../../store/reducer/Map/action';
import Geocode from "react-geocode";
import Globals from "../../Global/Constant";
import { ToastsStore } from "react-toasts";
import followUserService from "../../services/followUserService";

class UserProfile extends Component {

  state = {
    user: ''
  }

  constructor(props) {
    super(props);
    Cookies.get('accessToken') == null ? this.props.history.push('/') : this.props.history.push(this.props.location.pathname);
    this.getUserProfile();
  }

  async getUserProfile() {
    var user = (this.props.location.pathname).split('/')[2];
    await userProfile.getdetail(user).then(data => {
      if (!data.data) {
        this.props.history.replace('/home');
      }
      this.setState({
        user: data.data
      })
    });
  }

  componentDidMount() {
    this.props.action('none');
    this.getMyLocation();
  }

  getAddressfromLatlng(lat, lng) {
    Geocode.setLanguage('en')
    Geocode.setApiKey(Globals.mapApi)

    Geocode.fromLatLng(lat, lng).then(
      response => {
        const address = response.results[0].formatted_address;
        this.props.ChangeMapLocation(address)
      },
      error => {
        return error;
      }
    );
  }
  getMyLocation(isCustom = false) {
    Geocode.setLanguage('en')
    Geocode.setApiKey(Globals.mapApi)
    const location = window.navigator && window.navigator.geolocation



    if (location) {
      location.getCurrentPosition((position) => {
        console.log('position', position);
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
          response => {
            this.props.typeofLocation(response.results[0]['types']);
            this.props.placeIDChange(response.results[0]['place_id']);
            // this.setStateData(response.results[0]['address_components'], position.coords.latitude, position.coords.longitude)
            this.props.latitudeChange(position.coords.latitude)
            this.props.longitudeChange(position.coords.longitude)
            this.getAddressfromLatlng(position.coords.latitude, position.coords.longitude)
            this.props.isMapLoaded('true')
          },
          error => {
            console.log("Geocode.fromLatLng error", error);
          }
        )
      }, (error) => {
        console.log("getCurrentPosition error", error);
        this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
      })
    }

  }

  handleFollowing = (count) => {
    this.setState({ followingCount: count });
  }

  handleFollower = (count) => {
    this.setState({ followerCount: count });
  }

  onFollow = async id => {
    try {
      await followUserService.add(id);
      this.setState({ user: { ...this.state.user, isFollowing: 1 } });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        ToastsStore.error("An unexpected error occurrred. Please Try Again.");
    }
  }

  onUnFollow = async id => {
    try {
      await followUserService.remove(id);
      this.setState({ user: { ...this.state.user, isFollowing: 0 } });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        ToastsStore.error("An unexpected error occurrred. Please Try Again.");
    }
  }

  render() {

    return (
      <div className='col-md-12'>
        <Header />
        <section className="wrapper_sec bg_grey">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="map_area">
                  {this.state.user.id ? <Map user={this.state.user.id} zoom={2} focusCenter={true} /> : ''}
                </div>
                <div className="profile_divwrap">
                  <div className="row">
                    <div className="col-xl-2 col-lg-2 col-md-3 col-12 p-0">
                      <div className="pro_img_post">
                        <img src={this.state.user.profile_image} alt='' className="img-fluid profile_img" />
                      </div>
                    </div>
                    <div className="col-md-10 col-12">
                      <div className="wrap_profile_detail d-flex align-items-center flex-wrap">
                        <div className="name_div same_wrap">
                          <h1>{this.state.user.fname + " " + this.state.user.lname}</h1>
                          <div className="follower_div">
                            <span><span className="clr_seagreen">{(this.state.followingCount != '') ? this.state.followingCount : '-'}</span> Following</span>
                            <span>|</span>
                            <span>{(this.state.followerCount != '') ? this.state.followerCount : '-'} Followers</span>
                          </div>
                        </div>
                        <div className="interest_div same_wrap">
                          <h4>Interset</h4>
                          <p>{this.state.user.interset}</p>
                        </div>
                        <div className="info_div same_wrap">
                          <h4>Info</h4>
                          <p>{this.state.user.info}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {this.state.user.isFollowing == 0 &&
                      <div class="col-md-1">
                        <button onClick={() => this.onFollow(this.state.user.id)} className="btn btn-primary">Follow</button>
                      </div>
                    }
                    {this.state.user.isFollowing == 1 &&
                      <div class="col-md-1">
                        <button onClick={() => this.onUnFollow(this.state.user.id)} className="btn btn-primary">UnFollow</button>
                      </div>
                    }

                    {this.state.user.isFollowing == 1 &&
                      <div class="col-md-3 ">
                        <Link to="/messages" className="btn btn-primary">Message</Link>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="wrap_timeline mt-5 pt-3">
              <div className="row">
                <div className="col-lg-4 col-md-5 col-12">
                  {(this.state.user.id) ? <> <UserGallery userId={this.state.user.id} />
                    <Follower userId={this.state.user.id} onGetCount={this.handleFollower} />
                    <Following userId={this.state.user.id} onGetCount={this.handleFollowing} /> </> : ''}
                  <Footer />
                </div>
                <div className="col-lg-8 col-md-7 col-12">
                  <div className="wrap_timeline_post">
                    {/*<div className="post_div">*/}
                    {/*  <div className="post_area">*/}
                    {/*    <img src="assets/images/post_profile_img.png" className="img-fluid" />*/}
                    {/*    <textarea className="form-control" placeholder="Click here to Veen your current location and update your status?"></textarea>*/}
                    {/*  </div>*/}
                    {/*  <div className="ft_post d-flex align-items-center justify-content-end">*/}
                    {/*    <a href="#" className="ml-3"><img src="assets/images/post_ic_tag.png" className="img-fluid" /></a>*/}
                    {/*    <a href="#" className="ml-3"><img src="assets/images/post_ic_cam.png" className="img-fluid" /></a>*/}
                    {/*    <a href="#" className="ml-3"><img src="assets/images/post_ic_attach.png" className="img-fluid" /></a>*/}
                    {/*    <a href="#" className="ml-3"><img src="assets/images/post_ic_smily.png" className="img-fluid" /></a>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div className="unlimited_posts bg_white">
                      {(this.state.user.id) ? <UserProfileTimeline userId={this.state.user.id} /> : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    action: (display) => dispatch({
      type: PROFILE_LOAD,
      display: display
    }),
    ChangeMapLocation: description => dispatch(CursorPositionChange(description)),
    latitudeChange: latitude => dispatch(Latitude(latitude)),
    typeofLocation: type => dispatch(typeofLocation(type)),
    placeIDChange: id => dispatch(placeIDChange(id)),
    longitudeChange: longitude => dispatch(Longitude(longitude)),
    isMapLoaded: IsMap => dispatch(IsMapLoaded(IsMap)),
  }
};

export default connect(null, mapDispatchToProps)(UserProfile)

