import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL ;

export async function getMessageService(receiver_id) {
  const {data} = await http.get(apiEndpoint+ "/message/", {params:{receiver_id :receiver_id} });
  return data.data;
}

export default {getMessageService};
