import React, {Component} from 'react'
import PostMap from '../../component/Map/postMap'

export default class LocationModel extends Component {

    state = {
        post: this.props.mdata,
    }

    componentDidMount() {
        // console.log(this.props.mdata);
    }

    
    render() {
        return (
            <div className=" modal fade show"  id="modal-chat" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" style={{overflowY: 'auto', background: 'rgba(0,0,0,0.8)', display: 'block', paddingRight: '17px'}}>
                <div className="modal-dialog"  role="document">
                    <div className="modal-content">                    
                        <div className="modal-body">
                            <a className="close"  onClick={this.props.hideLocationModelPop} href="javascript:;">&times;</a>
                            <div className="row">
                                <div className="col-sm-12">
                                    <PostMap postLocation={this.props.mdata} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}
