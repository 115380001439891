import React, { Component } from 'react'
import ReactTimeAgo from "react-time-ago";
import {viewMessageService} from "../../services/viewMessageService";
import {NEW_MESSAGE_LIST} from '../../store/reducer/Socket/type';
import {connect} from "react-redux";

export class MessageBoxComponent extends Component {

    state={
        currentMessage: null
    }

    componentDidUpdate(prevProps, prevState) {
        if(JSON.stringify(this.props) == JSON.stringify(prevProps) && JSON.stringify(prevState) == JSON.stringify(this.state))
        {
            // console.log("if",prevProps, this.props, prevState, this.state);
        }
        else {
            if(this.props.showLoader){
                this.setState({currentMessage:null})
            }
            // console.log(prevProps, this.props, prevState, this.state);
            this.scrollToBottom();
            this.updateNEwMessage();
        }
    }

    updateNEwMessage = () =>{
        let isStateNull=null
        // if(this.state.currentMessage !== null)
        // {
        //     this.setState({currentMessage:null});
        // }
        if(this.props.newMessageList !== undefined){
            let currentMessage = this.props.newMessageList.filter(item => item.sender_id == this.props.selectedUserId);
            let newMessage = this.props.newMessageList.filter(item => item.sender_id !== this.props.selectedUserId);
            if(this.props.newMessageList.length !== newMessage.length)
            {
                // console.log("this call",newMessage,currentMessage);
                this.props.setNewMessage(newMessage);
                this.setState({currentMessage:currentMessage})
                // setTimeout(()=> this.setState({currentMessage: null}), 30000)
            }
            // if(currentMessage.length<1)
            // {
            //     this.setState({currentMessage:null})
            // }
            // console.log("this call updateNEwMessage in if",currentMessage);
        }
        // console.log("this call updateNEwMessage");
    }

    componentDidMount () {
        this.scrollToBottom();
        this.updateNEwMessage();
    }

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" })
        this.props.messages.map((item) => {
            // console.log(item);
            if (item.is_receive === 0 && this.props.selectedUserId !== item.receiver_id && item.id>0) {
                 viewMessageService(item.id);
            }
        })
    }

  timeSince(date) {

  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

  convertUTCDateToLocalDate = (date) => {
    date = new Date(date);
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return this.timeSince(newDate);
  }

    render() {
        var messagesList = [];
        if(this.props.showLoader){
            messagesList.push(<div key={'loader'} className="img_container2"><i className="fa fa-spinner fa-spin spin1"></i></div>);
            messagesList.push( <div key={'last'} id={'last'} className="align-self-end bg_green mb-4"
                                    ref={(el) => { this.messagesEnd = el; }}>
            </div>)
        }
        else if(this.props.messages !== undefined  && this.props.messages.length > 0) {
            messagesList = this.props.messages.slice(0).reverse().map((item, i) =>
                <div key={i} className={this.props.selectedUserId == item.receiver_id ? "align-self-end bg_green mb-4":"align-self-start bg_white mb-4" }>
                    <p className="chat_message" style={{backgroundColor: (!item.is_receive && this.props.selectedUserId !== item.receiver_id) ?'#b1b1b1':''}} >{item.message}</p>
                    <span className="message_time"> {this.convertUTCDateToLocalDate(item.created)}</span>
                </div>
            );
            if(this.state.currentMessage){
               let newMessage= this.state.currentMessage.map((item,i)=>
                   (this.props.selectedUserId == item.sender_id)?
                    <div key={i} className={this.props.selectedUserId == item.receiver_id ? "align-self-end bg_green mb-4":"align-self-start bg_white mb-4" }>
                        <p className="chat_message" style={{backgroundColor: (!item.is_receive && this.props.selectedUserId !== item.receiver_id) ?'#b1b1b1':''}} >{item.message}</p>
                        <span className="message_time">{this.convertUTCDateToLocalDate(item.created)}</span>
                    </div>:''
                )
                messagesList.push(newMessage);
            }
            messagesList.push( <div key={'last'} id={'last'} className="align-self-end bg_green mb-4"
                                    ref={(el) => { this.messagesEnd = el; }}>
            </div>)

        }
        return (messagesList)
    }
}
const mapStateToProps = (state) => {
    return {
        newMessageList: state.socket.new_message_list
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setNewMessage: (messages) => dispatch({
            type: NEW_MESSAGE_LIST,
            new_message_list: messages
        })
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(MessageBoxComponent);
