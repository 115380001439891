import { SELECT_CATEGORY} from './type'

export default (state = [], action) => {

    switch (action.type) {
        case SELECT_CATEGORY:
            return { ...state,category: action.category};
            default:
            return state;
    }
}
