import React, { Component } from 'react'
import ReactTimeAgo from "react-time-ago";

export default class CoverImage extends Component {
   timeSince(date) {

  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

  convertUTCDateToLocalDate = (date) => {
    date = new Date(date);
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return this.timeSince(newDate);
  }
        
    render() {
        return (
            this.props.coverImg ?
            <div className="pro_img">
                <img src={(Array.isArray(this.props.coverImg.files) && this.props.coverImg.files.length) ?this.props.coverImg.files[0].filepath:''} alt='' className="img-fluid" />
                <div className="overlay_content">
                    <h4>{this.props.coverImg.created !== undefined ? this.convertUTCDateToLocalDate(this.props.coverImg.created) :''} at <span className="clr_seagreen">{this.props.coverImg.location}</span></h4>
                    <h1>{this.props.coverImg.body}</h1>
                    <p>{this.props.coverImg.like_count} Veens</p>
                </div>
            </div>:''
        )
    }
}
