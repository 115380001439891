import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL ;

export async function sendMessageService(receiver_id, message) {
  const {data} = await http.post(apiEndpoint+ "/message/create/", { receiver_id, message});
  return data.data;
}

export default {sendMessageService};
