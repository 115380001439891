import React, {Component} from 'react'

export default class ImageModel extends Component {

    state = {
        post: this.props.mdata,
    }

    componentDidMount() {
        console.log(this.props.mdata);
    }

    render() {
        return (
            <div className=" modal fade show"  id="modal-chat" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" style={{overflowY: 'auto', background: 'rgba(0,0,0,0.8)', display: 'block', paddingRight: '17px'}}>
                <div className="modal-dialog"  role="document">
                    <div className="modal-content">

                        <div className="modal-body">
                            <a className="close"  onClick={this.props.hideModelPop} href="javascript:;">&times;</a>
                            <div className="row">
                                <div className="col-sm-12">
                                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                    <ol className="carousel-indicators">
                                        {this.state.post.post.files.map((images, i) => {
                                                return   <li key={i} data-target="#carouselExampleIndicators" data-slide-to={i} className={this.state.post.indexId == i ? "active":""}></li>
                                        })}
                                    </ol>
                                    <div className="carousel-inner">
                                        {
                                            this.state.post.post.files.map((images, i) => {
                                                return   <div key={i} className={this.state.post.indexId == i ? "carousel-item text-center active":"carousel-item text-center"}>
                                                            <img src={this.state.post.post.files[i].filepath} alt="img not found" className="img-fluid"/>
                                                        </div>
                                        })}

                                    </div>
                                    <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                    <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}
