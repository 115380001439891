import React, { Component } from 'react'
import Follow from '../Follow'
import Veening from '../Veening'
import Footer from '../../component/Footer/index';
import { createBrowserHistory } from 'history';
import { connect } from "react-redux";

 class RightSidebar extends Component {
    render() {
        const divStyle = {
            display : this.props.header.display
       
           };
        const history = createBrowserHistory();
        const path = history.location.pathname.toString();
        const Show = (path === '/messages')? false : true;
        return (
                    <div className="col-xl-3 col-lg-3 col-md-3 pr-0 pr-xs-15" style={divStyle}>
                        {Show ?
                    <div className="veenme_rightbar pt-4">
                        <Follow />
                        <Veening/>
                        <br/>
                        <div >
                            <video  controls autoPlay={true} muted={true} style={{ width : '100%'}}>
                                <source src="./assets/videos/Veen Me _R3.mp4" type="video/mp4"/>
                            </video>
                        </div>
                        <Footer/>
                    </div>
                    : <></>}
                </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        header: state.header
    }
  };
  
  
  
  export default connect(mapStateToProps, null)(RightSidebar);