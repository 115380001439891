import React, { Component } from 'react';
import Geocode from 'react-geocode'
import Globals from '../../Global/Constant'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { connect } from "react-redux";
import { mapChange, Latitude, Longitude, CursorPositionChange, typeofLocation, placeIDChange } from '../../store/reducer/Map/action'
import API from '../../Global/PrivateAPI';
import gallery from "../../services/galleryService";
import GalleryModel from "../Gallery/GalleryModel";
class Maps extends Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = {
            longitude: null,
            latitude: null,
            country: null,
            c_state: null,
            city: null,
            street: 'Searching...',
            isStreet: false,
            location : [],
            center : {},
            modelShow:[],
            show: false,
        }

    }


    componentDidMount(){
        this._isMounted = true;
        if(this.props.user){ var url = Globals.api + '/profile/get-veens?user='+this.props.user;}
        else if(this.props.placeId){ var url = Globals.api + '/profile/get-veens?place='+this.props.placeId;}
        //else { var url = Globals.api + '/profile/get-veens';}
		else { var url = Globals.api + '/profile/near-by-veens?latitude=24.8780324&longitude=67.0686896';}
        API.get(url).then(data=>{
            this.setState({
             location: (data.data) ? data.data : []
            })
         })
    }
    componentWillUnmount(){
        this._isMounted = false;
    }
    displayMarkers = () => {
        return (this.props.onCursorChange)?<Marker key={1} id={1} position={{
            lat: this.props.location.latitude,
            lng: this.props.location.longitude
        }}
                       label={(this.props.onCursorChange)?{
                           text: "Selected Place",
                           fontFamily: "Arial",
                           fontWeight: "bold",
                           fontSize: "12px",
                       }:null}
            onClick={() => {
                    console.log("You clicked me!");
                    this.getAddressfromLatlng(this.props.location.latitude,  this.props.location.longitude)
                }
            }
        />:'';
    }

    getAddressfromLatlng(lat, lng) {
        Geocode.setLanguage('en')
        Geocode.setApiKey(Globals.mapApi)

        Geocode.fromLatLng(lat, lng).then(
            response => {
                if(this.props.onCursorChange)
                    this.props.onCursorChange(response.results[0])
                this.props.typeofLocation(response.results[0]['types']);
                const address = response.results[0].formatted_address;
                this.props.ChangeMapLocation(address)
                this.setState({center:{lat:lat,lng:lng}})
            },
            error => {
                return error;
            }
        );
    }

    onMapClick = (mapProps, map, event) => {
        console.log('Onclick called');
        this.props.latitudeChange(event.latLng.lat())
        this.props.longitudeChange(event.latLng.lng())
        this.displayMarkers();
        this.getAddressfromLatlng(event.latLng.lat(), event.latLng.lng());
    }

    onMapMarkerClick = ( mapProps, map, event ) => {
        console.log('Onclick onMapMarkerClick called', mapProps.post_id, this.props.user);
		this.getPostById(mapProps, map, event);
        // gallery.getUserGalleryList(null, {user_id: this.props.user, post_id:  mapProps.post_id}).then(res => {
        //     this.setState({
        //         modelShow: res.item[0],
        //         show: true,
        //     })
        // })
    }

	getPostById = (mapProps, map, event) => {
        var url = Globals.api +'/profile/get-veen-by-id?id='+mapProps.post_id;
        API.get(url).then(res => {
           
            var postData = res.data;
            var marker = new Marker({
                position: event.latLng,
                map: map
            });

            var infoWindow = new window.google.maps.InfoWindow({
                content : '<div class="timeline_box"><div class="box_inner d-flex align-items-start">' +
                    '<div class="box_inner_content"><div class="row">' +
                    '<div class="col-md-12 name_div">'+
                    '<img class="img_circle" src="'+postData.user_profile_image+'">'+
                    '<strong><b>'+ postData.user_fname +' '+postData.user_lname+'</b> '+postData.activity_title  +
                    '<img src="'+postData.activity_icon+'">'+
                    '</strong> at ' +
                    '<span>'+postData.location+'</span>' +
                    '</div>' +
                    '<div class="col-md-12"><p>'+postData.body+'</p></div>' +
                    '</div></div></div></div>'
            });
            //console.log(infoWindow)
            infoWindow.setPosition(event.latLng);console.log("asd");
            infoWindow.open(map);

        });
    }

    getNearByVeens = (lat, lng) =>{
        var url = Globals.api + '/profile/near-by-veens?latitude='+lat+'&longitude='+lng;
        API.get(url).then(data=>{
            this.setState({
                location: (data.data) ? data.data : []
            })
        })
    }
	
	hideModelPop = () => {
        this.setState({ show: false });
    }

     _mapLoaded(mapProps, map) {
            map.setOptions({
                styles: mapStyle
            })
        }

    render() {
        const map = this.props.isMap
        const lat = this.props.location.latitude; //this.props.updateMap === undefined? 39.8097343: this.props.updateMap.lat;
        const lng = this.props.location.longitude;//-98.5556199
        let initalCenter = {lat:lat, lng:lng}
        if(!this.state.center)
            initalCenter = this.state.center;

        if(this.props.updateMap !== undefined)
        {
            this.getAddressfromLatlng(this.props.updateMap.lat, this.props.updateMap.lng)
        }
        return (

            (map === false) ?
                (this.state.isStreet === true) ? (
                    <textarea readOnly className='form-control' value={this.state.street}>  </textarea>) : null
                :
                (
                    <div style={{ position: "relative", height: "50vh" }}>
                        {(this.props.location.isMapLoaded) ? (
                            <Map
                                onClick={this.onMapClick}
                                google={this.props.google}
                                zoom={(this.props.zoom)?this.props.zoom:19}
                                initialCenter= {initalCenter}
                                center={(this.state.location[0] !== undefined && this.props.focusCenter !== undefined) ? {lat:this.state.location[0].latitude,lng:this.state.location[0].longitude} : this.state.center}
                              onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
                            >
                              {
                                  this.state.location.map( (map,i)=>
                                            <Marker
                                            onClick={this.onMapMarkerClick}
                                            key={i}
                                            post_id = {map.id}
                                            title={map.location}
                                            name={map.location}
                                            position={{lat:map.latitude, lng: map.longitude}} 
                                            icon={{
                                            url: require('../../map-marker.png') ,
                                            anchor: this.props.google.maps.Point(32,32),
                                            scaledSize: this.props.google.maps.Size(64,64)
                                        }} />
                                  )
                              }
                           
                                                               
                                        {this.displayMarkers()}
                            </Map>
                        ) : null}
                        {(this.state.show) ? <GalleryModel hideModelPop={this.hideModelPop} mdata={this.state.modelShow}/> : ''}
                    </div>
                )

        );
    }
}
    const mapStyle = [
        {
            featureType: 'landscape.natural.landcover',
            elementType: 'geometry.fill',
            stylers: [
                {
                    "visibility": "on"
                },
                {                     
                    color: '#6fb257'            
                }
            ]
        },
        {
            featureType: 'transit.line',
            elementType: 'geometry.fill',
            stylers: [
                {
                    "visibility": "on"
                },
                {                
                    color: '#d7f443'                
                }
            ]
        },
        {
            featureType: 'landscape',
            elementType: 'geometry.fill',
            stylers: [
                 {
                    "visibility": "on"
                },
                {   
                    color: '#9dc8a4'                
                }
            ]
        },
        {
            featureType: 'road',
            elementType: 'geometry.fill',
            stylers: [
                {
                 "visibility": "on"
                },
                {   
                    color: '#dff443'                
                }
            ]
        },
        {
            featureType: 'road.local',
            elementType: 'geometry.fill',
            stylers: [
                {
                 "visibility": "off"
                },
                {   
                    color: '#dff443'                
                }
            ]
        },
    ]
const mapStateToProps = (state) => {
    return {
        location: state.location
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        ChangeMapLocation: description => dispatch(CursorPositionChange(description)),
        latitudeChange: latitude => dispatch(Latitude(latitude)),
        longitudeChange: longitude => dispatch(Longitude(longitude)),
        placeIDChange :  id =>  dispatch(placeIDChange(id)),
        typeofLocation : type => dispatch(typeofLocation(type)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
    apiKey: (Globals.mapApi)
})(Maps))
