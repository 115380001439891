import React, { Component } from 'react'
import Button from '../../component/Button'
import ExplorePost from '../../component/ExplorePost/index'
import { PROFILE_UNLOAD } from '../../store/reducer/Profile/type';
import { connect } from "react-redux";
import Cookies from 'js-cookie'
import CoverImage from "../../component/CoverImage/Index";
import PlaceSearchBox from "../../component/Places/placeSearchbox";
 class Explore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 'veenme',
            coverImg:null
        }
        Cookies.get('accessToken')==null ? this.props.history.push('/') : this.props.history.push('/explore');
        this.handleChangeButton = this.handleChangeButton.bind(this);
    }
    componentDidMount(){
        this.props.action('block');
    }
    handleChangeButton = input => event => {
        this.setState({
            tab: event.target.name
        })
    }
     handleCoverImg=(data)=>{
         this.setState({
             coverImg:data
         })
     }

    renderSwitch(param) {
        // console.log(param);
        switch (param) {
            case 'restaurants':
                // 'restaurant'
                return <ExplorePost type={1} coverImg={this.handleCoverImg} />;
            case 'landmarks':
                // 'landmark'
                return <ExplorePost type={2} coverImg={this.handleCoverImg} />;
            case 'historical':
                // 'historical'
                return <ExplorePost type={3} coverImg={this.handleCoverImg} />;
            case 'nature':
                // 'nature'
                return <ExplorePost type={4} coverImg={this.handleCoverImg} />;
            default:
                // 'restaurant'
                return <ExplorePost type={1} coverImg={this.handleCoverImg} />;
        }
    }
    render() {
        return (
            <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="timeline_wrap">
                    <div className="timeline_view">
                       <PlaceSearchBox/>
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <Button handleChange={this.handleChangeButton} name='restaurants' onClick={this.onClick} active={'active'}>Restaurants</Button>
                            <Button handleChange={this.handleChangeButton} name='landmarks'>Landmarks</Button>
                            <Button handleChange={this.handleChangeButton} name='historical'>Historical</Button>
                            <Button handleChange={this.handleChangeButton} name='nature'>Nature</Button>
                        </ul>

                        <CoverImage coverImg={this.state.coverImg} />
                        <div className="timeline_content">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="veen" role="tabpanel" aria-labelledby="veen-tab">
                                    {this.renderSwitch(this.state.tab)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        action: (display) => dispatch({
            type: PROFILE_UNLOAD,
            display: display
        })
    }
};

export default connect(null, mapDispatchToProps)(Explore)