import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import UserSearchBox from "../UserSearchBox";
import { getLatestMessageService } from "../../services/getLatestMessageService";
import { connect } from "react-redux";
import Cookies from "js-cookie";

export class Header extends Component {

  // componentDidMount() {
  //     console.log(this.props.newMessageList);
  // }

  render() {
    const messages = this.props.newMessageList;
    const newNotification = this.props.newNotificationList;
    return (
      <header className="">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-2 col-md-3">
              <Link to="/" className="clr_seagreen d-block logo_anch"> Veen<span className="clr_dark">Me</span></Link>
            </div>
            {(Cookies.get('userToken') || Cookies.get('token')) && <>
                <div className="col-lg-4 col-md-4">
                  {/*<div className="search_filed">*/}
                  {/*  <input type="text" name="" className="form-control" placeholder="Search"/>*/}
                  {/*  <button className="fas fa-search btn"></button>*/}
                  {/*</div>*/}
                  <UserSearchBox />
                </div>
                <div className="col-lg-6 col-md-6 profile-page-menu " >
                  <div className="header_notification">
                    <div className="left_nav">
                      <ul>
                        <li><Link  to="/notifications" ><span>{newNotification && newNotification.length>0?<span className="active_tag">{newNotification.length}</span>:''}<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="bell" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-bell fa-w-14 fa-7x"><path fill="currentColor" d="M439.39 362.29c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71zM67.53 368c21.22-27.97 44.42-74.33 44.53-159.42 0-.2-.06-.38-.06-.58 0-61.86 50.14-112 112-112s112 50.14 112 112c0 .2-.06.38-.06.58.11 85.1 23.31 131.46 44.53 159.42H67.53zM224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64z" className=""></path></svg></span><span>Notifications</span></Link></li>
                        <li><Link  to="/messages" ><span>{messages && messages.length>0?<span className="active_tag">{messages.length}</span>:''}<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-envelope fa-w-16 fa-7x"><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z" className=""></path></svg></span><span>Messages</span></Link></li>
                        <li><Link  to="/profile" ><svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-user fa-w-14 fa-5x"><path fill="currentColor" d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z" className=""></path></svg><span>Profile</span></Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
                </>
            }
          </div>
        </div>
      </header>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    newMessageList: state.socket.new_message_list,
    newNotificationList: state.socket.new_notification_list
  }
};
export default connect(mapStateToProps)(Header);
