import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/post/";

function movieUrl(id) {
    return `${apiEndpoint}/${id}`;
}

export function getPublicPosts(page = 1) {
    return http.get(apiEndpoint+'public-posts', {params: {page: page}});
}
export function createPost(post) {

    return http.post(apiEndpoint+'create', post);
}
export function updatePost(id,post) {

    return http.post(apiEndpoint+'update?id='+id, post);
}

export default {
    getPublicPosts,
    createPost,
    updatePost
};


