import axios from "axios";
import {ToastsStore} from "react-toasts";

axios.interceptors.response.use(null, error => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
      console.log(error)
      ToastsStore.error("An unexpected error occurrred. Please Try Again.");

  }

  return Promise.reject(error);
});

function setJwt(jwt) {
  axios.defaults.headers.authorization='Bearer '+ jwt;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt
};
