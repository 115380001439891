import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL ;

export async function getNotificationService() {
  const {data} = await http.get(apiEndpoint+ "/notification/");
  return data.data;
}

export default {getNotificationService};
