import API from "../Global/PrivateAPI";
import Globals from "../Global/Constant";

const explore = {
    getList: function(type){
        if(!type)
            type= 'restaurant';
        return API.get( Globals.api + '/explore/?type='+type).then(data => {
            return {item: (data.data.item !== undefined && data.data.item.length > 0) ? data.data.item : [] ,
                next: (data.data._links.next === undefined)  ? null  : data.data._links.next.href};
        });
    }
}

export default explore;