import Cookies from 'js-cookie'
import Globals from "./Constant";

export default {
    get: (URL) => {
        // console.log('url', URL)
      return fetch(URL,{
        headers: new Headers({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + Cookies.get('accessToken')
      })
    }).then(res => res.json()).catch();
    },

    post : (URL,formData) => {
        return fetch(URL, {
            'method': 'post',
            headers: new Headers({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get('accessToken')
            }),

            body: formData
        }).then(res => res.json());
    }
  }