import React, {Component} from 'react'
import gallery from "../../services/galleryService";
import InfiniteScroll from "react-infinite-scroll-component";
import GalleryModel from "./GalleryModel";
import Globals from "../../Global/Constant";

export default class UserGallery extends Component {

    state = {
        list: [],
        nextUrl: null,
        count: 0,
        show: false,
        modelShow: null
    }

    componentDidMount() {
        let user = null;
        if(this.props.userId)
        {
            user = {id: this.props.userId};
        }
        else
        {
            let data = localStorage.getItem('user');
            user = JSON.parse(data);
        }
        // console.log("user",user);
        gallery.getUserGalleryList(null, {user_id: user.id}).then(res => {


            this.setState({
                list: res.item,
                nextUrl: res.next,
                count: res.meta.totalCount,
            })
        })
    }

    showModelPop = (item) => {
        this.setState({ modelShow: item });
        this.setState({ show: true });
    }

    hideModelPop = () => {
        this.setState({ show: false });
    }

    createList = () => {
        var list = [];
        if (this.state.list !== undefined && this.state.list.length > 0) {
            list = this.state.list.map((item, i) =>
                <a key={i} href="javascript:;" onClick={()=>this.showModelPop(item)}><img src={item.filepath} className="img-fluid"/></a>
            );
        }
        return list;
    }

    render() {
        var hasMore = (this.state.nextUrl) ? true : false;

        return (

<>
    {(this.state.show) ? <GalleryModel hideModelPop={this.hideModelPop} mdata={this.state.modelShow}/> : ''}
            <div className="timeline_photos">
                <div
                    className="photos_head d-flex align-items-center justify-content-between flex-wrap pt-2 pb-3 px-1">
                    <h4>Photos</h4>
                    <p>{this.state.count} Pics</p>
                </div>
                <div className="d-flex flex-wrap">

                    {this.createList()}

                </div>
                {(hasMore) ?
                    <button className="center ft_green_box green_box list_greenbox" onClick={this.nextPage}>
                        Show more
                    </button> : null}
            </div>
</>
        )

    }
}
