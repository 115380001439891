import API from "../Global/PrivateAPI";
import Globals from "../Global/Constant";

const followUser = {
    add: function(id){
        return API.get(Globals.api + '/follow/follow?user_id='+id).then(data => {
            return data;
        });
    },
    remove: function(id){
        return API.get(Globals.api + '/follow/unfollow?user_id='+id).then(data => {
            return data;
        });
    }
}

export default followUser;