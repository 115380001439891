import React, { Component } from 'react'
import ImageUploader from 'react-images-upload';
import Globals from '../../Global/Constant';
import { connect } from "react-redux";
import Cookies from 'js-cookie'
import { upload } from '../../store/reducer/Uploads/action';
import { Add } from '../../store/reducer/Uploads/action';
class UploadImage extends Component {

    constructor(props) {
        super(props);
        this.state = { artistGallery: [], uploadedPicsCount: 0, loading: false };
        this.onDrop = this.onDrop.bind(this);
    }
    onDrop = async (picture) => {
        console.log(picture.length);
        if (picture.length > 0) {
            this.setState({ loading: true })
            var fileIds = [];
            // var index = picture.length-1;
            for (var index = this.state.uploadedPicsCount; index < picture.length; index++) {
                this.setState({ uploadedPicsCount: this.state.uploadedPicsCount + 1 })

                const formData = new FormData();
                formData.append('file', picture[index], picture[index].name);

                await fetch(Globals.api + '/file/upload', {
                    'method': 'post',
                    headers: new Headers({
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + Cookies.get('accessToken')
                    }),

                    body: formData
                }).then(res => res.json()).then((response) => {
                    this.setState({ loading: false })
                    if (response.data == null) {
                        const data = JSON.parse(JSON.stringify(response.errors))
                        var er = Object.keys(data).map(function (key) {
                            return data[key];
                        });
                        this.setState({ errors: er })
                    } else {
                        this.setState({ artistGallery: picture });
                        this.props.AddImage(picture);
                        fileIds.push(response.data.id);
                        console.log(response.data);
                    }
                })
            }
            this.props.AddImageIDs(fileIds);
        }

    };

    save = (e) => {
        e.preventDefault();
        this.props.save();
    }

    back = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    render() {
        return (
            <>
                <ul id="progressbar">
                    <li></li>
                    <li></li>
                    <li ></li>
                    <li ></li>
                    <li className="active"></li>
                </ul>
                <fieldset className="upload black-color location">
                    <div className="upper-step row">
                        <div className="col-8 pl-0">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1"><i className="far fa-images"></i></span>
                                </div>
                                <label className="btn btn-default veenme-upload">
                                    Upload Photo
                            </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <ImageUploader
                                withIcon={true}
                                buttonText='Choose images'
                                onChange={this.onDrop}
                                imgExtension={['.jpg', '.JPEG', '.gif', '.jfif', '.png', '.gif']}
                                maxFileSize={20244890}
                                singleImage={false}
                                label='Max file size: 20mb, accepted: jpg | gif | png'
                            />
                            <div className="row">
                                {this.props.data.file.file && this.props.data.file.file.map((file, i) => (

                                    <div className="col-2" key={i}><img key={i} width="100" height="100" src={URL.createObjectURL(file)} /></div>
                                ))}
                                <div className="col-sm-12">
                                    {(this.state.loading == true) ? "Uploading ..." : ""}
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <button className="next action-button btn" onClick={this.back}>Back</button>
                        </div>
                        <div className="col-6">
                            <button disabled={this.state.loading} className="next action-button-previous btn" onClick={this.save}>Veen {this.state.loading}</button>
                        </div>
                    </div>
                </fieldset>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        data: state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        AddImage: file => dispatch(upload(file)),
        AddImageIDs: id => dispatch(Add(id))
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadImage);