import React, { Component } from 'react'
import FollowerService from "../../services/followerListService";
import Globals from "../../Global/Constant";
import Cookies from "js-cookie";
import {Link} from "react-router-dom";
import followUserService from "../../services/followUserService";

export default class Follower extends Component {
    

    constructor(props) {
        super(props);
        this.state = {
            followerList: [],
            nextUrl: null,
            loading: false,
            totalCount:0
        }
    }

    componentDidMount() {
        this.getFollowerList()
    }

    getFollowerList(){

        console.log('this.props.userId', this.props.userId)

        var url = (this.props.userId) ? Globals.api + '/follow/follower-list?q=&user_id=' + this.props.userId : null ;
        FollowerService.getList(url).then(res => {
            this.setState({
                    followerList: res.item,
                    nextUrl: res.next,
                    totalCount: res.totalCount
                })
            this.props.onGetCount(res.totalCount);
        })
    }

    async onFollow(id) {
        await followUserService.add(id);
        this.getFollowerList()
    }

    async onUnFollow(id) {
        await followUserService.remove(id);
        this.getFollowerList()
    }

    nextPage = () => {
        this.setState({ loading: true });
        if(this.state.nextUrl) {
            FollowerService.getList(this.state.nextUrl).then(res => {
                this.setState({
                    followerList: this.state.followerList.concat(res.item),
                    nextUrl: res.next
                })
            })
            setTimeout(function() {
                this.setState({ loading: false });
            }.bind(this), 500)
        }
    }

    createList = () => {
        var list = [];
        if(this.state.followerList !== undefined  && this.state.followerList.length > 0) {
            list = this.state.followerList.map((item, i) =>
                <li key={i}>
                    <Link to={(Cookies.get('usernameid') == item.username) ? "/profile" : "/user/"+item.username } >
                        <div className="avatar_profile d-flex align-items-center">
                            <img src={item.profile_image} alt='' className="img-fluid icon-image"/>
                            <h5>{item.name}</h5>
                        </div>
                    </Link>
                    {Cookies.get('usernameid') != item.username && !item.isFollowing && <button onClick={() => this.onFollow(item.user_id)} className="btn btn_default">Follow</button>}
                    {Cookies.get('usernameid') != item.username &&  item.isFollowing && <button onClick={() => this.onUnFollow(item.user_id)} className="btn btn_default">UnFollow</button>}
                </li>);
        }
        return list;
    }

    getPageBtn = () => {
        return  (this.state.nextUrl) ? <a href="#!" onClick={() => this.nextPage()} className="ft_green_box">Show more</a>: '';
    }

    render() {
        const loading = this.state.loading;
        return (
            <div className="green_box follow_box mb-4 p-3">
                <h4>Follower {this.state.totalCount === undefined ? 0 :this.state.totalCount}</h4>
                <div className="list_greenbox">
                  <ul>
                      {this.createList()}
                  </ul>
                </div>
                {loading ? <div className="img_container2"><i className="fa fa-spinner fa-spin spin1"></i></div> : ""}
                {this.getPageBtn()}
            </div>
        )
    }
}
