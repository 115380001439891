import React, { Component } from 'react'
import { connect } from "react-redux";
import { PROFILE_LOAD } from '../../store/reducer/Profile/type';
import Header from '../../component/Header';
import Footer from '../../component/Footer/index'
import Cookies from 'js-cookie'
import { Link } from "react-router-dom";
import Globals from "../../Global/Constant";
import { ToastsStore } from "react-toasts";
import Form from "../../component/Common/form";
import Joi from "joi-browser";
import auth from "../../services/authService";

class ChangePassword extends Form {

  state = {
    user: '',
    data: { old_password: "", new_password: "", confirm_password: "" },
    errors: {},
    loading: false,
    serverErrorMessage: '',
    dateErrorMsg: ''
  }

  schema = {
    old_password: Joi.string()
      .required()
      .label("Old Password")
      .min(5),
    new_password: Joi.string()
      .required()
      .label("New Password")
      .min(5),
    confirm_password: Joi.string()
      .required()
      .label("Confirm Password")
      .min(5),

  };

  constructor(props) {
    super(props);
    Cookies.get('accessToken') == null ? this.props.history.push('/') : this.props.history.push(this.props.location.pathname);
  }


  componentDidMount() {
  }

  doSubmit = async () => {
    // this.register();
    try {
      this.setState({ loading: true })
      const data = await auth.changePassword(this.state.data);
      this.setState({ loading: false })
      if (data.success) {
        window.location.href = Globals.appHomePage;
      } else {
        this.setState({ loading: false, serverErrorMessage: data.data.message })
      }
      // console.log(data)
      // auth.loginWithJwt(data.data.token);
      // this.setState({loading: false})
      // window.location = "/";
    } catch (ex) {
      console.log(ex)
      if (ex.response && (ex.response.status >= 400 && ex.response.status < 500)) {
        const errors = { ...this.state.errors };
        let res = ex.response.data.data
        console.log('errors', res)
        res.forEach(element => {
          errors[element.field] = element.message;
        });
        // this.state.serverErrorMessage = ex.response.data.message
        this.setState({ errors, loading: false });
      }
    }
  };

  render() {

    return (
      <div className='col-md-6'>
        <div style={{ textAlign: 'center', paddingTop: '50px' }}>
          <h3>Change Password</h3>
          <form onSubmit={this.handleSubmit} style={{ paddingTop: '20px' }}>
            {this.renderInput("old_password", "Old Password*", 'password')}
            {this.renderInput("new_password", "New Password*", 'password')}
            {this.renderInput("confirm_password", "Confirm Password*", 'password')}
            {this.renderButton("Submit", "btn btn-block submit-btn btn-primary")}

          </form>
          <div className="col-md-12" style={{paddingTop: '10px'}}>
            {this.state.loading === true && <span><i style={{ color: '#46807d', position: 'absolute' }} className="fa fa-spin spinner-border"></i></span>}
          </div>
        </div>

      </div>
    )
  }
}


export default ChangePassword

