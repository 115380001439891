import API from "../Global/PrivateAPI";
import Globals from "../Global/Constant";

const userProfile = {
    getdetail: function(userName){
        return API.get( Globals.api + '/user/get-user-by-username?username='+userName).then(data => {
            return {data: (data.data !== undefined) ? data.data : [] };
        });
    }
}

export default userProfile;