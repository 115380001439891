import React, { Component } from 'react'
import MightFollowingService from "../../services/mightFollowListService";
import {Link} from "react-router-dom";
import followUserService from "../../services/followUserService";
import FollowingService from "../../services/followingListService";
import Cookies from "js-cookie";
import {ToastsStore} from "react-toasts";

export default class Follow extends Component {
    state = {
        followList: [],
        nextUrl: null,
        loading: false
    }
    componentDidMount() {
        if( Cookies.get('accessToken')!=undefined)
            MightFollowingService.getList().then(res => {
            this.setState({
                followList: res.item,
                nextUrl: res.next
            })
        })
    }
    onFollow = async id => {

        const originalList =  this.state.followList;
        const followList = originalList.filter(m => m.id !== id);
        ToastsStore.success("Followed Successfully");

        this.setState({ followList });

        try {
            await followUserService.add(id);
        } catch (ex) {
            console.log(ex)
            if (ex.response && ex.response.status === 404)
                ToastsStore.error("An unexpected error occurrred. Please Try Again.");


            this.setState({ followList: originalList });
        }


    }

    nextPage = () => {
        // console.log("nextpage click");
        if(this.state.nextUrl) {
            this.setState({ loading: true });
            MightFollowingService.getList(this.state.nextUrl).then(res => {
                // console.log("nextpage click", res);
                this.setState({
                    followList: this.state.followList.concat(res.item),
                    nextUrl: res.next
                })
            })
            setTimeout(function() {
                this.setState({ loading: false });
            }.bind(this), 500)
        }
    }

    createList = () => {
        var list = [];
        if(this.state.followList !== undefined  && this.state.followList.length > 0) {
            list = this.state.followList.map((item, i) =>
                <li key={i}>
                    <Link to={(Cookies.get('usernameid') == item.name) ? "/profile" : "/user/"+item.username } >
                        <div className="avatar_profile d-flex align-items-center">
                            <img src={item.profile_image}alt='' className="img-fluid img_circle"/>
                            <h5>{item.name}</h5>
                        </div>
                    </Link>
                    <button onClick={() => this.onFollow(item.id)} className="btn btn_default">Follow</button>
                </li>);
        }
        return list;
    }

    getPageBtn = () => {
        return  (this.state.nextUrl) ? <a href="#!" onClick={() => this.nextPage(this.state.nextUrl)} className="ft_green_box">Show more</a>: '';
    }

    render() {
        const loading = this.state.loading;
        return (
            <div className="green_box follow_box mb-4">
            <h4>You might like to follow</h4>
            <div className="list_greenbox">
              <ul>
                  {this.createList()}
              </ul>
            </div>
                {loading ? <div className="img_container2"><i className="fa fa-spinner fa-spin spin1"></i></div> : ""}
                {this.getPageBtn()}
          </div>
        )
    }
}
