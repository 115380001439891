import React, { Component } from 'react'
import userSearch from "../../services/userSearch";
import {Link} from "react-router-dom";
import Cookies from "js-cookie";
export default class UserSearchBox extends Component {

    constructor(props) {
        super(props);
        this.items = ["item1", "item2", "item3"];
        this.state = {
            suggestions:[],
            text: '',
            selected: null,
            loading: false
        }
    }

    getSuggestions = async (value) => {
        this.setState({loading: true});

        await userSearch.getdetail(value).then(data => {
            this.setState({
                suggestions: data.data,
                loading:false
            })
        });
    }

    onTextChanged = async (e) => {
        const value =  e.target.value;
        // let suggestions =[];
        if(value.length > 2)
        {
             this.getSuggestions(value)
            // const regex = new RegExp(`^${value}`,'i');
            // suggestions = this.items.sort().filter(v =>regex.test(v));
        }
        else {
            this.setState(() => ({suggestions: []}));
        }
        this.setState(() => ({text: value}));
    }

    suggestionsSetected (value, name) {
        this.setState(() => ({text: name, suggestions: [], selected: value }))
    }

    renderSuggestions () {
        const suggestions = this.state.suggestions;
        if (suggestions.length)
        {
            return <ul style={{
                'listStyleType': 'none',
                'textAlign': 'left',
                'border': '1px solid grey',
                'borderRadius': '15px',
                'padding': '1px 1px 1px 15px'
            }}>
                {suggestions.map((item, i) =>
                    <Link to={(Cookies.get('usernameid') == item.username) ? "/profile" : "/user/" + item.username}>
                        <li key={i}
                            onClick={() => this.suggestionsSetected(item.username, item.fname + " " + item.lname)}><img
                            src={item.profile_image} alt=''
                            className="img-fluid icon-image"/> {item.fname + " " + item.lname}</li>
                    </Link>
                )}
                    </ul>
        }
    }

    render() {
        const { text } = this.state;
        return (
            <>
            <div className="search_filed">
                <input autoComplete="off" style={{zIndex: '1'}}  type="text" name="search_box" value={text} onChange={this.onTextChanged}
                       className="form-control" placeholder="Enter for search…"/>
                {this.state.loading === true && <i className="fa fa-spinner fa-spin spin1"></i>}
                {this.renderSuggestions()}
            </div>
            </>
        )
    }
}