export const Globals = {
    // api: 'http://localhost/veenme/api/web/index.php/v1',
    api: 'https://veenmeapi.thesupportonline.net/index.php/v1',
    App: 'Youchug',
    title: 'YouChug',
    appHomePage: '/home',
    appIndexPage: '/',
    video_base_path : 'http://frontend.youchug.com/storage/',
    host:window.location.host,
    // mapApi:'AIzaSyDe5jSVuTBIubIFfjvwfwOGLGCh3gd6HvA',

    mapApi:'AIzaSyAgyE4igQMRYeBwWQ7LTWBe4ovIcmJP-dQ',
    socketUrl:'https://socket-veenme.thesupportonline.net:3332/', //live
    // socketUrl:'http://localhost:3332/', //local

    gmailLoginKey: '91859080597-gbk6l8atitovkj8do063e65tcd79itic.apps.googleusercontent.com',
    // gmailLoginKey: '153031041953-v27gle10roehjn87101ih94p7or49lv4.apps.googleusercontent.com', // local

    facebookAppId: '387364209126331'

}

export default Globals
