import React, { Component } from 'react'
import {getNotificationService} from "../../services/getNotificationService";
import ReactTimeAgo from "react-time-ago";
import {viewNotificationService} from "../../services/viewNotificationService";
import { connect } from "react-redux";
import {NEW_NOTIFICATION_LIST} from "../../store/reducer/Socket/type";
import {Link} from "react-router-dom";

class NotificationComponent extends Component {

    state = {
        notifications : null
    }

    componentDidMount() {
        this.getNotification();
    }

    getNotification =async () =>{
        let data = await getNotificationService();
        this.setState({notifications:data.item});
        this.viewNotification();
    }

    viewNotification = () =>{
        if(this.state.notifications !== undefined  && this.state.notifications.length > 0) {
            this.state.notifications.map((item) => {
                if (item.is_receive === 0 ) {
                    viewNotificationService(item.id);
                }
            })
        }
        this.props.setNewNotification(null);
    }
    timeSince(date) {

  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

  convertUTCDateToLocalDate = (date) => {
    date = new Date(date);
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return this.timeSince(newDate);
  }
    getNotificationList = () =>{
        console.log(this.state.notifications);
        var list = [];
        if(this.state.notifications !== undefined  && this.state.notifications.length > 0) {
            list = this.state.notifications.map((item, i) =>
                (item.user)?
                <div key={i} className="notifications_wrap">
                    <div className="notification_listing">
                        <div className="notifications_box d-flex align-items-center flex-wrap">
                            <div className="notification_img">
                                <img src={item.user.profile_image} alt='' style={{maxWidth:"100px"}} className="img-fluid"/>
                            </div>
                            <div className="notification_content ml-3">
                                <p><span>{item.user.fname}</span> added {item.notification_type} 
                                <Link  to="/post/" ><span className="clr_seagreen"> {item.text}</span>
                                </Link>
                                </p>
                                <span>{item.created !== undefined ?  this.convertUTCDateToLocalDate(item.created) :''}</span>
                            </div>
                        </div>

                    </div>
                </div> : "")
        }
        return list;
    }

    render() {
        return (
          <>
              {this.state.notifications ? this.getNotificationList() : ''}
      </>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setNewNotification: (messages) => dispatch({
            type: NEW_NOTIFICATION_LIST,
            new_notification_list: messages
        })
    }
};
export default connect(null, mapDispatchToProps)(NotificationComponent);