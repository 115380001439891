import React, { Component } from 'react'
import Joi from "joi-browser";
import { Link } from 'react-router-dom';
import Form from "../Common/form";
import auth from "../../services/authService"
import ForgetPassword from '../ForgetPassword/index';


export default class SignIn extends Form {
    constructor(props){
        super(props);
        // this.login = this.login.bind(this);
    }

    state = {
        errors: {},
        data: {email: "", password: ""},
        redirect: true,
        ShowloginForm: false,
        loading: false,
        serverErrorMessage: ''
    }

    schema = {
        email: Joi.string()
            .required()
            .label("Email")
            .email(),
        password: Joi.string()
            .required()
            .label("Password")
            .min(5)
    };
    doSubmit = async () => {
        try {
            this.setState({loading: true})
            const { data } = this.state;
            await auth.login(data.email, data.password)
            this.setState({loading: false})
            window.location = "/home";
        } catch (ex) {
            console.log(ex)
            if (ex.response && (ex.response.status >= 400 && ex.response.status < 500)) {
                const errors = { ...this.state.errors };
                let res = ex.response.data.data
                console.log('errors',res)
                res.forEach(element => {
                    errors[element.field] = element.message;
                });
                // this.state.serverErrorMessage = ex.response.data.message
                this.setState({ errors,loading:false });
            }
        }
    };
    render() {
        return (
            <>
                <p>Sign in now and be a part of the <br /> World’s fastest growing online <br /> community.</p>

                {this.state.serverErrorMessage.length > 1 && <div id='error' className='alert alert-danger'>
                    <li>{this.state.serverErrorMessage.toString()}</li>
                </div>}
                <div className="inner-form-box">

                    <form onSubmit={this.handleSubmit}>

                        {this.renderInput("email", "Email", 'email')}
                        {this.renderInput("password", "Password", "password")}
                        {this.renderButton("Login", "btn btn-block submit-btn")}
                        {this.state.loading === true && <i className="fa fa-spinner fa-spin spin1"></i>}

                    </form>
                    <ForgetPassword />
                </div>
            </>
        )
    }
}
