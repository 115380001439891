import React, { Component } from 'react'
import ReactAvatar from "react-avatar-edit";
import UploadProfilePic from '../../services/uploadProfilePictureService';

export default class Avatar extends Component {

    constructor(props) {
        super(props);

        // var outside
        // fetch(props.src,{method: 'POST',
        //     mode: 'no-cors',
        //     cache: 'default'}).then(res => res).then(images => {
        //     // outside = URL.createObjectURL(images)
        //     // console.log("fetchapi---",outside);
        //     console.log("fetchapi---",images);
        // }).catch();


        // var request = require('request').defaults({ encoding: null });
        // request.get(props.src, function (error, response, body) {
        //     if (!error && response.statusCode == 200) {
        //         var data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
        //         console.log("base64---", data);
        //     }
        // });
        this.state = {
            preview: null,
            src: null,
            originalImg: null
        }

        this.getBase64Image(props.src);

        this.onCrop = this.onCrop.bind(this)
        this.onClose = this.onClose.bind(this)
        this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this)
    }

    onClose() {
        this.setState({preview: null})
    }

    onCrop(preview) {
        this.setState({preview})
    }

    onBeforeFileLoad(elem) {
        if(elem.target.files[0].size > 71680){
            alert("File is too big!");
            elem.target.value = "";
        };
    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    uploadImage = async () => {
        var ImageURL = this.state.preview;
        // Split the base64 string in data and contentType
        var block = ImageURL.split(";");
        // Get the content type
        var contentType = block[0].split(":")[1];// In this case "image/gif"
        // get the real base64 content of the file
        var realData = block[1].split(",")[1];
        // Convert to blob
        var blob = this.b64toBlob(realData, contentType);

        // Create a FormData and append the file
        var fd = new FormData();
        fd.append("file", blob);
        if(this.state.originalImg)
        { fd.append("original_file", this.state.originalImg); }
        else {
            var block2 = this.state.src.split(";");
            var contentType2 = block2[0].split(":")[1];
            var realData2 = block2[1].split(",")[1];
            var blob2 = this.b64toBlob(realData2, contentType2);
            fd.append("original_file", blob2);
        }
        await UploadProfilePic.upload(fd);
        window.location.reload();
    }
    onFileLoad = (data) => {
        this.setState({originalImg: data});
    }

    // const getEmergencyFoundImg = urlImg => {
    //     var img = new Image();
    //     img.src = urlImg;
    //     img.crossOrigin = 'Anonymous';
    //
    //     var canvas = document.createElement('canvas'),
    //         ctx = canvas.getContext('2d');
    //
    //     canvas.height = img.naturalHeight;
    //     canvas.width = img.naturalWidth;
    //     ctx.drawImage(img, 0, 0);
    //
    //     var b64 = canvas.toDataURL('image/png');
    //     console.log("b64", b64);
    //     return b64;
    // };

    getBase64Image = (imgUrl) => {
        var comp = this;
        var img = new Image();
        img.src = imgUrl;
        img.crossOrigin = 'Anonymous';
        img.onload = function () {
            this.crossOrigin = 'Anonymous';
            var canvas = document.createElement('canvas');
            canvas.height = this.naturalHeight;
            canvas.width = this.naturalWidth;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(this, 0, 0);
            try {
                comp.setState({src: canvas.toDataURL('image/jpeg')})

            }catch (e) {
                console.log(e)
            }
            // console.log("img data--", canvas.toDataURL('image/jpeg'))
        };
    }

    render() {
        return (
            <form id={'imageform'} width={800} heigth={400} encType={'multipart/form-data'}>
                <div className={'row'}>
                    <div className={'col-md-8'}>
                        {(this.state.src) ? <ReactAvatar
                            width={300}
                            height={240}
                            imageWidth={290}
                            onCrop={this.onCrop}
                            onClose={this.onClose}
                            src={this.state.src}
                            onFileLoad={this.onFileLoad}
                        /> : null}
                    </div>
                    <div className={'col-md-4'}>
                        <img src={this.state.preview} width={'100%'} alt="Preview" />
                    </div>
                    <br />
                    <a href="#!" onClick={this.uploadImage} className="next action-button btn">Update</a>
                </div>
            </form>
        )
    }
}
