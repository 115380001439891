import API from "../Global/PrivateAPI";
import Globals from "../Global/Constant";

const mightFollowerList = {
    getList: function(url){
        return API.get((url) ? url : Globals.api + '/follow/might-follow-list').then(data => {
            return {item: (data.data.item !== undefined && data.data.item.length > 0) ? data.data.item : [] ,
                next: (data.data._links.next === undefined)  ? null  : data.data._links.next.href};
        });
    }
}

export default mightFollowerList;