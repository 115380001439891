import React, { Component } from "react";
import MessageComponent from "../../component/Message/index";
import { PROFILE_UNLOAD } from "../../store/reducer/Profile/type";
import { connect } from "react-redux";
import Cookies from "js-cookie";
class Message extends Component {
  constructor(props) {
    super(props);
    Cookies.get("accessToken") == null
      ? this.props.history.push("/")
      : this.props.history.push("/messages");
  }
  componentDidMount() {
    this.props.action("block");
  }

  render() {
    return (
        <MessageComponent />
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    action: display =>
      dispatch({
        type: PROFILE_UNLOAD,
        display: display
      })
  };
};

export default connect(null, mapDispatchToProps)(Message);
