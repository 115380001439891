import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {getPlaces} from "../../services/placeService";

export default class PlaceSearchBox extends Component {

    constructor(props) {
        super(props);
        this.items = [];
        this.state = {
            suggestions: [],
            text: '',
            selected: null,
            loading: false
        }
    }

    getSuggestions = async (value) => {
        this.setState({loading: true});
        let {data} = await getPlaces(value);
        this.setState({
            suggestions: data.data,
            loading: false
        })

    }

    onTextChanged = async (e) => {
        const value = e.target.value;
        this.setState(() => ({text: value}));
        if (value.length > 2) {
            this.getSuggestions(value)
        } else {
            this.setState(() => ({suggestions: []}));
        }
    }

    suggestionsSelected(value) {
        this.setState(() => ({text: value, suggestions: []}))
    }

    renderSuggestions() {
        const suggestions = this.state.suggestions;
        if ((suggestions == null || suggestions.length === 0) && this.state.text.length > 2) {
            return <ul style={{
                'listStyleType': 'none',
                'textAlign': 'left',
                'border': '1px solid grey',
                'borderRadius': '15px',
                'padding': '1px 1px 1px 15px'
            }}>
                <li>No Place Found</li>
            </ul>
        } else if (suggestions.length) {
            return <ul style={{
                'listStyleType': 'none',
                'textAlign': 'left',
                'border': '1px solid grey',
                'borderRadius': '15px',
                'padding': '1px 1px 1px 15px'
            }}>
                {suggestions.map((item, i) =>
                    <Link key={i} to={"/place/" + item.id}>
                        <li key={i}
                            onClick={() => this.suggestionsSelected(item.location)}>
                            {item.location}</li>
                    </Link>
                )}
            </ul>
        }
    }

    render() {
        const {text} = this.state;
        return (
            <>
                <div className="search_filed">
                    <input autoComplete="off" type="text" name="search_box" value={text} onChange={this.onTextChanged}
                           className="form-control " style={{zIndex: '9'}} placeholder="Enter for search…"/>
                    {this.state.loading === true && <i className="fa fa-spinner fa-spin spin1"></i>}
                    {this.renderSuggestions()}
                </div>
            </>
        )
    }
}