import React, { Component } from 'react'
import exploreService from "../../services/exploreService";

export default class ExplorePost extends Component {

    state = {
        type:null,
        postList: [],
        nextUrl: null
    }

     async getdata() {
         if(this.props.type !== this.state.type) {
             await exploreService.getList(this.props.type).then(res => {
                 this.setState({
                     postList: res.item,
                     nextUrl: res.next,
                     type: this.props.type
                 });
                 var index=0;
                 for(var i=0; i<res.item.length ; i++)
                 {
                    if(Array.isArray(res.item[i].files) && res.item[i].files.length)
                    {
                        index=i;
                        break;
                    }
                 }
                 this.props.coverImg(res.item[index])
             });
         }
     }

    createList = () => {
        var list = [];
        if(this.state.postList !== undefined  && this.state.postList.length > 0) {
            list = this.state.postList.map((item, i) =>
                <div key={i} className="timeline_box py-4 px-4">
                    <div className="explore_box d-flex align-items-center flex-wrap">
                        <div className="explore_content">
                            <h4>{item.created} at <span className="clr_seagreen">{item.location}</span></h4>
                            <h1>{item.body}</h1>
                            {/*<p>{item.like_count} Veens</p>*/}
                        </div>
                        <div className="explore_img">
                            <img src={(item.files[0] && item.files[0].filepath) ? item.files[0].filepath : "assets/images/explore_img1.png" } alt='' className="img-fluid" />
                        </div>
                    </div>
                </div>
            );
        }
        return list;
    }

    render() {
        this.getdata();
        // console.log("render param----",this.props.type ,this.state.type)
        return (
            <>
                {this.createList()}
            </>
        )
    }
}
