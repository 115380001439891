import React, {Component} from 'react'
import gallery from "../../services/galleryService";
import ReactTimeAgo from "react-time-ago";
import API from "../../Global/PrivateAPI";
import Globals from "../../Global/Constant";
import {Link} from "react-router-dom";
import Cookies from "js-cookie";
import {ToastsStore} from "react-toasts";
import commentService, {postComments} from "../../services/commentService";
import {connect} from "react-redux";

class GalleryModel extends Component {

    state = {
        post: this.props.mdata,
        comment: '',
        sub_comment:''
    }

    componentDidMount() {
        console.log('test')
        this.CallCommentAPI(this.state.post.post_id)
    }

     CallCommentAPI = async (post_id) => {
        let {data} = await commentService.getComments(post_id)
        // var key = "comment_" + this.state.post.post_id
        // var val = data.data.item
        // var obj = {}
        // obj[key] = [val]


        this.setState({
            commentList: data.data.item
        })
    }

    toggleLike = (e) => {
        e.preventDefault();

                let value = this.state.post.is_like
                this.state.post.is_like = !value;

                API.get(Globals.api + '/like/like?post_id=' + e.currentTarget.dataset.id)
                    .then(data => {

                    })

                if (this.state.post.is_like) {
                    this.state.post.like_count = parseInt(this.state.post.like_count) + 1
                }
                else {
                    this.state.post.like_count = parseInt(this.state.post.like_count) - 1
                }

                this.forceUpdate()
    }

    toggleCommentLike = (e) =>{
        e.preventDefault();
        var key = "comment_" + e.currentTarget.dataset.id
        // if ( this.state.key == e.currentTarget.dataset.id) {
        //this.props.LikePostToggle(i)

        let value = this.state.commentList[e.currentTarget.dataset.index].is_like
        this.state.commentList[e.currentTarget.dataset.index].is_like = !value;
        var comment_id = (e.currentTarget.dataset.commentid)?e.currentTarget.dataset.commentid:null;
        API.get(Globals.api + '/like/like?post_id=' + e.currentTarget.dataset.id+'&comment_id='+comment_id)
            .then(data => {

            })

        if (this.state.commentList[e.currentTarget.dataset.index].is_like) {
            this.state.commentList[e.currentTarget.dataset.index].like_count = parseInt(this.state.commentList[e.currentTarget.dataset.index].like_count) + 1
        }
        else {
            this.state.commentList[e.currentTarget.dataset.index].like_count = parseInt(this.state.commentList[e.currentTarget.dataset.index].like_count) - 1
        }

        this.forceUpdate()
        // }
    }

    sendNotificationOnSocket = async (data)=>{
        console.log('sendNotificationOnSocket');
        const socket = this.props.socket;
        console.log('socket',socket);
        if(data.user_id !== undefined )
        {
            socket.emit('new_notification', {
                text: data.notification.text,
                user_id: data.user_id,
                notification_type: data.notification.notification_type
            });
        }
    }

    saveSubComment = async (e) => {
        if (this.state.sub_comment != '') {
            var post_id = e.currentTarget.dataset.id;
            const formData = new FormData();
            formData.append('post_id', e.currentTarget.dataset.id);
            formData.append('text', this.state.sub_comment);
            formData.append('parent_id', e.currentTarget.dataset.commentId);

            try {
                let {data} = await commentService.postComments(formData);
                this.sendNotificationOnSocket(data.data);
                this.setState({
                    sub_comment: '',
                    currentCommentclick: false
                })
                ToastsStore.success("Comment submitted succesfully");
                // this.IncreaseComment(post_id);
                this.GetCommentByID(post_id);
                this.forceUpdate();
            }catch (ex) {
                console.log('error', ex)
                const errors = {...this.state.errors};
                let res = ex.response.data.data
                console.log('errors', res)
                res.forEach(element => {
                    errors[element.field] = element.message;
                    ToastsStore.error(element.message);
                });
                this.setState({errors, loading: false});
            }
        }
    }


    saveComment = async (e) => {
        if (this.state.comment != '') {
            var post_id = e.currentTarget.dataset.id;

            const formData = new FormData();
            formData.append('post_id', e.currentTarget.dataset.id);
            formData.append('text', this.state.comment);

            try {
                let {data} = await commentService.postComments(formData);
                this.sendNotificationOnSocket(data.data);
                this.setState({
                    comment: ''
                })
                ToastsStore.success("Comment submitted succesfully");
                // this.IncreaseComment(post_id);
                this.GetCommentByID(post_id);
                this.forceUpdate();
            }catch (ex) {
                console.log('errors', ex)

                const errors = {...this.state.errors};
                let res = ex.response.data.data
                res.forEach(element => {
                    errors[element.field] = element.message;
                    ToastsStore.error(element.message);
                });
                this.setState({errors, loading: false});
            }
        }
    }

    GetCommentByID(id) {
        this.CallCommentAPI(id);
      }

    CallCommentAPI(post_id) {
        API.get(Globals.api + '/comment/get-comment?post_id=' + post_id)
          .then(data => {
            var key = "comment_" + post_id
            var val = data.data.item
            var obj = {}
            obj[key] = [val]
    
            this.setState({
              ...obj
            })
          })
      }

  timeSince(date) {

  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

  convertUTCDateToLocalDate = (date) => {
    date = new Date(date);
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return this.timeSince(newDate);
  }

    showComment = () => {
        var list = [];
        if(this.state.commentList !== undefined  && this.state.commentList.length > 0) {
            list = this.state.commentList.map((item, i) =>
                <div key={i}>
                <div className="box_inner d-flex align-items-start cstm-area-chats mt-2">
                    <img src={item.user_profile_image} className="img-fluid img_circle"/>
                    <div className="box_inner_content">
                        <div className="name_div">
                            <h4 className="d-inline-block">{item.user_name}</h4>
                        </div>
                        <div className="dropdown">
                            <Link to="/" onClick={this.toggleCommentLike} data-id={item.post_id} data-index={i} data-commentid={item.id}><i
                                className={(item.is_like) ? "fas fa-thumbs-up" : "fas fa-thumbs-up grey"}></i>
                                <span>{(item.like_count)?item.like_count:null}</span></Link>
                            &nbsp;
                        </div>
                        <div className="middle_box pt-0 pb-0">
                            <p>{item.text}</p>
                            {/*<div className="reply">*/}
                            {/*    <Link to={'/'} data-id={item.id} style={{color: '#4aa19f'}}*/}
                            {/*          className="cstm-chat this">Reply <i*/}
                            {/*        className="fas fa-reply cstm-chat this"></i></Link>*/}
                            {/*</div>*/}
                        </div>
                        <div id={"comment_"+item.id} style={{display: item.showReplay ? 'block': 'block'}}
                            className={"comment-box-post"}>
                            <ul>
                                <li>
                                    <img src={this.state.post.user_profile_image}
                                         className='img-fluid img_circle m-lg-n1' alt=""/>
                                </li>
                                <li className="center" style={{width:"50%"}}>
                                    <input type="text" className="form-control"
                                           value={this.state.sub_comment}
                                           onChange={e => this.setState({sub_comment: e.target.value})}
                                           placeholder="Add a comment..."/>
                                </li>
                                <li>
                                    <button data-id={this.state.post.post_id} data-comment-id={item.id} style={{color: "#4aa19f"}}
                                            onClick={this.saveSubComment} className="fas fa-reply cstm-chat this">Reply
                                    </button>
                                </li>
                            </ul>
                        </div>
                        { item.comments.map((commentsItem, i) =>
                            <div key={i} className="box_inner d-flex align-items-start cstm-area-chats mt-2">
                                <img src={commentsItem.user_profile_image} className="img-fluid img_circle"/>
                                <div className="box_inner_content">
                                    <div className="name_div">
                                        <h4 className="d-inline-block">{commentsItem.user_name}</h4>
                                    </div>
                                    <div className="middle_box pt-0 pb-0">
                                        <p>{commentsItem.text}</p>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    </div>
                </div>
                <hr className="cstm-hr mt-0"/>
                </div>
        );
        }
        console.log("list", this.state.commentList);
        return list;
    }

    render() {
        return (
            <div className=" modal fade show"  id="modal-chat" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" style={{overflowY: 'auto', background: 'rgba(0,0,0,0.8)', display: 'block', paddingRight: '17px'}}>
                <div className="modal-dialog"  role="document">
                    <div className="modal-content">

                        <div className="modal-body">
                            <a style={{float : 'right', color: 'black'}} onClick={this.props.hideModelPop} href="javascript:;">X</a>
                            <div className="row">
                                <div className="col-lg-7 p-0">
                                    <div className="main-img-modal">
                                        {this.state.post.filepath ? <img src={this.state.post.filepath} alt="img not found"/> : ''}
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="main-chat-section">
                                        <div className="user-img">
                                            <img className="img_circle" src={this.state.post.user_profile_image} alt=""/>
                                            {this.state.post.user_fullname}
                                        </div>
                                        <div className="cstm-para">
                                            <p> {this.state.post.post_text}</p>
                                        </div>
                                        <hr className="cstm-hr"/>
                                            <div className="ft_box">
                                                <div className="ft_inner_ft pt-0 pl-0 pr-0">
                                                    <ul>
                                                        <li>
                                                            <Link to="/" onClick={this.toggleLike} data-id={this.state.post.post_id}><i
                                                                className={(this.state.post.is_like) ? "fas fa-thumbs-up" : "fas fa-thumbs-up grey"}></i>
                                                                <span>{this.state.post.like_count}</span></Link>
                                                        </li>
                                                        <li>
                                                            <a href="javascript:;" className="commit-box-click"><i
                                                                className="fas fa-comment-alt"></i> <span>{this.state.post.comment_count}</span></a>
                                                        </li>
                                                    </ul>
                                                    <span className="hour_class">{this.state.post.post_created !== undefined ? this.convertUTCDateToLocalDate(this.state.post.post_created) :''}</span>
                                                </div>
                                            </div>
                                            <div className="comment-box-post mt-10 cstm-area">
                                                <ul>
                                                    <li>
                                                        <img className="img-fluid img_circle"  src={this.state.post.user_profile_image} alt=""/>
                                                    </li>
                                                    <li className="center" style={{width:"55%"}}>
                                                        <input type="text" className="form-control" value={this.state.comment}
                                                               onChange={e => this.setState({comment: e.target.value})}
                                                               placeholder="Add a comment..."/>
                                                    </li>
                                                    <li>
                                                        <input type="submit" className="btn" value="Send" data-id={this.state.post.post_id} onClick={this.saveComment} />
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="scroll-area" id="style-1">

                                                {this.state.commentList ? this.showComment():''}
                                            </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )

    }
}
const mapStateToProps = (state) => {
    return {
        data: state,
        socket: state.socket.socket,
    }
};
export default connect(mapStateToProps, null)(GalleryModel);
