import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import placeVeenList from "../../services/placeVeenListService";

export default class Veening extends Component {
    state = {
        list: []
    }
    componentDidMount() {
        placeVeenList.getList().then(res => {
            this.setState({
                list: res.item
            })
        })
    }
    createList = () => {
        var list = [];
        if(this.state.list !== undefined && this.state.list.length > 0) {
            list = this.state.list.map((item, i) =>
                <li key={i}>
                    <Link to={"/place/"+item.place_id}>
                        <div className="avatar_profile d-flex align-items-center">
                            <img src={item.image ? item.image : 'assets/images/map-marker.png' } alt='' className="img-fluid img_circle"/>
                            <h5 className="text-overflow-hidden">{item.location}<span>{item.veens} Veens</span></h5>
                        </div>
                    </Link>
                    {/*<span className="case_rating"><img src="assets/images/briefcase_ic.png" alt=''*/}
                    {/*                                   className="img-fluid"/> <span*/}
                    {/*    className="rating">8.2</span></span>*/}
                </li>);
        }
        return list;
    }
    render() {
        return (
            <div className="green_box people_box">
                <h4>People are veening</h4>
                <div className="list_greenbox">
                    <ul>
                        {this.createList()}
                    </ul>
                </div>
                {/*<Link to= "" className="ft_green_box"> Show more</Link>*/}
       
            </div>
        )
    }
}
