import React, { Component } from 'react';
import Geocode from 'react-geocode'
import Globals from '../../Global/Constant'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { connect } from "react-redux";
import { mapChange, Latitude, Longitude, CursorPositionChange, typeofLocation, placeIDChange } from '../../store/reducer/Map/action'
import API from '../../Global/PrivateAPI';
import gallery from "../../services/galleryService";
import GalleryModel from "../Gallery/GalleryModel";
import { Route , withRouter} from 'react-router-dom';
class PostMap extends Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = {
            longitude: null,
            latitude: null,
            country: null,
            c_state: null,
            city: null,
            street: 'Searching...',
            isStreet: false,
            location : [],
            center : {},
            modelShow:[],
            show: false,
        }

    }


    componentDidMount(){
        this._isMounted = true;
        if(this.props.user){ var url = Globals.api + '/profile/get-veens?user='+this.props.user;}
        else if(this.props.placeId){ var url = Globals.api + '/profile/get-veens?place='+this.props.placeId;}
        else { var url = Globals.api + '/profile/get-veens';}
        API.get(url).then(data=>{
            this.setState({
             location: (data.data) ? data.data : []
            })
         })
         console.log(this.props.postLocation);
         this.state.longtitude = this.props.postLocation.longtitude;
         this.state.latitude = this.props.postLocation.latitude;
    }
    componentWillUnmount(){
        this._isMounted = false;
    }
    displayMarkers = () => {
        return (this.props.onCursorChange)?<Marker key={1} id={1} position={{
            lat: this.props.postLocation.latitude,
            lng: this.props.postLocation.longitude
        }}
                       label={(this.props.onCursorChange)?{
                           text: "Selected Place",
                           fontFamily: "Arial",
                           fontWeight: "bold",
                           fontSize: "12px",
                       }:null}
            onClick={() => {
                    console.log("You clicked me!");
                    this.getAddressfromLatlng(this.props.postLocation.latitude,  this.props.postLocation.longitude)
                }
            }
        />:'';
    }

    getAddressfromLatlng(lat, lng) {
        Geocode.setLanguage('en')
        Geocode.setApiKey(Globals.mapApi)

        Geocode.fromLatLng(lat, lng).then(
            response => {
                if(this.props.onCursorChange)
                    this.props.onCursorChange(response.results[0])
                this.props.typeofLocation(response.results[0]['types']);
                const address = response.results[0].formatted_address;
                this.props.ChangeMapLocation(address)
                this.setState({center:{lat:lat,lng:lng}})
            },
            error => {
                return error;
            }
        );
    }

    onMapClick = (mapProps, map, event) => {
        console.log('Onclick called');
        this.props.latitudeChange(event.latLng.lat())
        this.props.longitudeChange(event.latLng.lng())
        this.displayMarkers();
        this.getAddressfromLatlng(event.latLng.lat(), event.latLng.lng());
    }

    onMapMarkerClick = ( mapProps, map, event ) => {
        console.log('Onclick onMapMarkerClick called', mapProps.post_id, this.props.user);
    
        let path = `/place/${this.props.postLocation.place_id}`;
        this.props.history.push(path);

        // gallery.getUserGalleryList(null, {user_id: this.props.user, post_id:  mapProps.post_id}).then(res => {
        //     this.setState({
        //         modelShow: res.item[0],
        //         show: true,
        //     })
        // })
    }

    hideModelPop = () => {
        this.setState({ show: false });
    }

        _mapLoaded(mapProps, map) {
            map.setOptions({
                styles: mapStyle
            })
        }
    render() {
        const map = this.props.isMap
        const lat = this.props.postLocation.latitude; //this.props.updateMap === undefined? 39.8097343: this.props.updateMap.lat;
        const lng = this.props.postLocation.longitude;//-98.5556199
        let initalCenter = {lat:lat, lng:lng}
        if(!this.state.center)
            initalCenter = this.state.center;

        if(this.props.updateMap !== undefined)
        {
            this.getAddressfromLatlng(this.props.updateMap.lat, this.props.updateMap.lng)
        }
        return (

            (map === false) ?
                (this.state.isStreet === true) ? (
                    <textarea readOnly className='form-control' value={this.state.street}>  </textarea>) : null
                :
                (
                    <div style={{ position: "relative", height: "50vh" }}>
                        {(this.props.location.isMapLoaded) ? (
                            <Map
                                onClick={this.onMapClick}
                                google={this.props.google}
                                zoom={(this.props.zoom)?this.props.zoom:15}
                                initialCenter= {initalCenter}
                                center={(this.state.location[0] !== undefined && this.props.focusCenter !== undefined) ? {lat:this.state.latitude,lng:this.state.longitude} : this.state.center}
                                onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
                            >
                              {
                                  
                                <Marker
                                    onClick={this.onMapMarkerClick}                                            
                                    position={{lat:lat, lng: lng}} 
                                    icon={{
                                    url: require('../../map-marker.png') ,
                                    anchor: this.props.google.maps.Point(32,32),
                                    scaledSize: this.props.google.maps.Size(64,64)
                                 }} />                                  
                              }                                                                                       
                                {this.displayMarkers()}
                            </Map>
                        ) : null}
                        {(this.state.show) ? <GalleryModel hideModelPop={this.hideModelPop} mdata={this.state.modelShow}/> : ''}
                    </div>
                )

        );
    }
}
    const mapStyle = [
        {
            featureType: 'landscape.natural.landcover',
            elementType: 'geometry.fill',
            stylers: [
                {
                    "visibility": "on"
                },
                {                     
                    color: '#6fb257'            
                }
            ]
        },
        {
            featureType: 'transit.line',
            elementType: 'geometry.fill',
            stylers: [
                {
                    "visibility": "on"
                },
                {                
                    color: '#d7f443'                
                }
            ]
        },
        {
            featureType: 'landscape',
            elementType: 'geometry.fill',
            stylers: [
                 {
                    "visibility": "on"
                },
                {   
                    color: '#9dc8a4'                
                }
            ]
        },
        {
            featureType: 'road',
            elementType: 'geometry.fill',
            stylers: [
                {
                 "visibility": "on"
                },
                {   
                    color: '#dff443'                
                }
            ]
        },
        {
            featureType: 'road.local',
            elementType: 'geometry.fill',
            stylers: [
                {
                 "visibility": "off"
                },
                {   
                    color: '#dff443'                
                }
            ]
        },
    ]

const mapStateToProps = (state) => {
    return {
        location: state.location
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        ChangeMapLocation: description => dispatch(CursorPositionChange(description)),
        latitudeChange: latitude => dispatch(Latitude(latitude)),
        longitudeChange: longitude => dispatch(Longitude(longitude)),
        placeIDChange :  id =>  dispatch(placeIDChange(id)),
        typeofLocation : type => dispatch(typeofLocation(type)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
    apiKey: (Globals.mapApi)
})(PostMap)))
