import API from "../Global/PrivateAPI";
import Globals from "../Global/Constant";

const placePost = {
    getdata: function(id,page){
        return API.get( Globals.api + '/place/posts?place_id='+id).then(data => {
            return {data: (data.data !== undefined) ? data.data : [] };
        });
    }
}

export default placePost;