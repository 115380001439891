import React, { Component } from 'react'
import { connect } from "react-redux";
import 'react-google-places-autocomplete/dist/assets/index.css';
import DisplayMap from '../../component/DisplayMap/index'
import CreatePost from '../CreatePost';
import { Link } from 'react-router-dom';
import { HIDE_MODAL } from '../../store/reducer/Modal/type';
import UploadImage from '../UploadImage';
import { Add } from '../../store/reducer/post/action';
import Activities from '../Activities';
import {createPost} from "../../services/postService";
import {ToastsStore} from "react-toasts";
import  io from "socket.io-client";
import Cookies from "js-cookie";
import Category from "../Category";
class ModalContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      location: '',
      step: 1,
      StatusDescription: '',
      post: null,
      activity: '',
      category: ''
    }
  }
  nextStep = () => {
    const { step } = this.state
    this.setState({
      step: step + 1
    })
  }

  sendNotificationOnSocket = async (data)=>{
    console.log('sendNotificationOnSocket');
    const socket = this.props.socket;
    console.log('socket',socket);
    if(data.follower !== undefined  && data.follower.length > 0)
    {
        await data.follower.map((item) => {
               socket.emit('new_notification', {
                text: data.notification.text,
                user_id: item.follower,
                notification_type: data.notification.notification_type
              });
          });
    }
  }
  save = async () => {
 
    const formData = new FormData();
    let post = this.props.data.post.post
    if(typeof post === 'object'){
      post = ''
    }
    formData.append('body', post);
    formData.append('activity_id', (this.props.data.activity.activity == null) ? 5 : this.props.data.activity.activity);
    formData.append('category_id', (this.props.data.category.category == null) ? 1 : this.props.data.category.category);
    formData.append('location', this.props.data.location.street);
    formData.append('longitude', this.props.data.location.longitude);
    formData.append('latitude', this.props.data.location.latitude);
    formData.append('place_id', this.props.data.location.place_id);
    this.props.data.location.type_of_location.map((type) => {
       formData.append('type[]', type);
    });
    this.props.data.file.file_id.map((file) => {
       formData.append('files[]', file);
    });

    console.log('formData',formData)

    try {
      const {data} = await createPost(formData);
      console.log(data);

      ToastsStore.success("Post created succesfully");

      this.sendNotificationOnSocket(data.data);

      this.props.HideModalClick('none');
      window.location.reload();
    } catch (ex) {

      const errors = {...this.state.errors};
      let res = ex.response.data.data
      console.log('errors', res)
      res.forEach(element => {
        errors[element.field] = element.message;
        ToastsStore.error(element.message);
      });
      this.setState({errors, loading: false});
    }




  };
  
  handleChange = input => event => {
    this.setState({ [input]: event.target.value })
    this.props.AddPost(event.target.value);
  }

  handleChangeCategory = value => {
    this.setState({ category: value })
  }

    handleLocationChange = loc => {
        this.setState({ location: loc })
  }
  handleActivitychange = value => {
    console.log('ac', value)
    this.setState({ activity: value })
  }

  prevStep = () => {
    const { step } = this.state
    this.setState({
      step: step - 1
    })
  }
  hideModal = () => {
    this.props.HideModalClick('none');
  };
  style = {
    'float': 'right',
    'marginTop': '-12px'
  }
  renderSwitch() {
    const { step } = this.state;
    const { StatusDescription, post, activity ,location} = this.state;
    const values = { StatusDescription, post, activity ,location};
    switch (step) {
      case 1:
        return <DisplayMap
          nextStep={this.nextStep}
          handleLocationChange={this.handleLocationChange}

          values={values}
        />
      case 2:
        return <CreatePost
          nextStep={this.nextStep}
          prevStep={this.prevStep}
          handleChange={this.handleChange}
          values={values}
        />
      case 3:
        return <Category
          nextStep={this.nextStep}
          prevStep={this.prevStep}
          handleChangeCategory={this.handleChangeCategory}
          values={values}
        />
      case 4:
        return <Activities
          nextStep={this.nextStep}
          prevStep={this.prevStep}
          handleActivitychange={this.handleActivitychange}
          values={values}
        />
      case 5:
        return <UploadImage
          nextStep={this.nextStep}
          save={this.save}
          prevStep={this.prevStep}
          handleChangeForImages={this.handleChangeForImages}
          values={values}
        />
      default:
        break;
    }
  }
  render() {
    return (
      <form id="msform">
        <Link style={this.style} to="" onClick={this.hideModal}><i className="fas fa-times-circle"></i></Link>
        {
          this.renderSwitch()

        }
      </form>
    );


  }
}
const mapStateToProps = (state) => {
  return {
    data: state,
    socket: state.socket.socket,
  }
};


const mapDispatchToProps = (dispatch) => {
  return {

    HideModalClick: (display) => dispatch({
      type: HIDE_MODAL,
      displayModal: display
    }),
      AddPost: post => dispatch(Add(post)),
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalContent);