import React, { Component } from 'react'
import DatePicker from 'react-date-picker';
import Errors from '../../component/Error/index'
import Cookies from 'js-cookie'
import Globals from '../../Global/Constant'
import Joi from "joi-browser";
import Form from "../Common/form";
import auth from "../../services/authService";

export default class SignUp extends Form {

    state = {
        errors: {},
        data: {fname: "", lname: "", email: "", phone: "", password: "", dob: "", gender: "male"},
        date: '',
        redirect: true,
        ShowloginForm: false,
        loading: false,
        serverErrorMessage: '',
        dateErrorMsg: ''
    }

    schema = {
        fname: Joi.string()
            .required()
            .label("First Name"),
        lname: Joi.string()
            .required()
            .label("Last Name"),
        email: Joi.string()
            .required()
            .label("Email")
            .email(),
        phone: Joi.string()
            .required()
            .label("Phone"),
        password: Joi.string()
            .required()
            .label("Password")
            .min(5),
        dob: Joi.date().error(() => {
            return {message: "\"DOB\" is required a valid date"};
        })
            .required()
            .label("DOB"),
        gender: Joi.string()
            .label("Gender")

    };

    onDateChange = d => {
        this.state.dateErrorMsg = this.validateProperty({name: 'dob', value: d})
        const data = {...this.state.data};
        data['dob'] = d ? (d.toISOString().slice(0, 10)) : "";
        this.setState({data, date: d})
    }
    register = async () => {
        this.setState({loading: true})


        await fetch(Globals.api + '/signup', {
            method: 'post',
            headers: new Headers({
                'Content-Type': 'application/json', // <-- Specifying the Content-Type
            }),
            body: JSON.stringify(this.state.data),
            // body: 'fname=' + this.state.fname + '&lname=' + this.state.lname + '&email=' + this.state.email + '&password=' + this.state.password + '&platform=web' + '&dob=' + '1995-04-30' + '&gender=' + this.state.gender + '&phone=' + encodeURIComponent(this.state.phone)
        }).then(res => res.json()).then((data) => {
            if (data.data.token) {
                Cookies.set('accessToken', data.data.token)
                this.setState({ loading: false })
                window.location.href = Globals.appHomePage;
            } else {
                this.setState({loading: false, serverErrorMessage: data.data.message})

            }
        }).then(console.log)
    }

    doSubmit = async () => {
        // this.register();
        try {
            this.setState({loading: true})
            const {data} = await auth.register(this.state.data);
            this.setState({loading: false})

            if (data.data.token) {
                window.location.href = Globals.appHomePage;
            } else {
                this.setState({loading: false, serverErrorMessage: data.data.message})
            }
            // console.log(data)
            // auth.loginWithJwt(data.data.token);
            // this.setState({loading: false})
            // window.location = "/";
        } catch (ex) {
            console.log(ex)
            if (ex.response && (ex.response.status >= 400 && ex.response.status < 500)) {
                const errors = { ...this.state.errors };
                let res = ex.response.data.data
                console.log('errors',res)
                res.forEach(element => {
                    errors[element.field] = element.message;
                });
                // this.state.serverErrorMessage = ex.response.data.message
                this.setState({ errors,loading:false });
            }
        }
    };

    render() {
        return (
            <div className="sign-up-form-box">
                <h1>Sign Up today!</h1>
                <h2>& Start Veening Now</h2>
                <div className="inner-form-box">

                    {this.state.serverErrorMessage.length > 1 && <div id='error' className='alert alert-danger'>
                        <li>{this.state.serverErrorMessage.toString()}</li>
                    </div>}

                    <form onSubmit={this.handleSubmit}>
                        {this.renderInput("fname", "First Name*")}
                        {this.renderInput("lname", "Surname*")}
                        {this.renderInput("email", "Email*", 'email')}
                        {this.renderInput("password", "Password*", 'password')}

                        <div className="form-group">
                            <label>Date of Birth*</label>
                            <DatePicker
                                name="date"
                                onChange={this.onDateChange}
                                isClearable
                                dayPlaceholder={'DD'}
                                monthPlaceholder={'MM'}
                                yearPlaceholder={'YYYY'}
                                value={this.state.date}
                                format="y-MM-dd"
                                required="true"
                            />
                            {this.state.dateErrorMsg && <div className="alert alert-danger">
                                {this.state.dateErrorMsg}
                            </div>}

                        </div>
                        <fieldset>
                            <div className="form-group">
                                <input type="radio" className="radio" name="gender" value="male"
                                       onChange={this.handleChange} checked={this.state.data.gender === 'male'}/>
                                <label>Male</label>
                                <input type="radio" className="radio" name="gender" value="female"
                                       onChange={this.handleChange} checked={this.state.data.gender === 'female'}/>
                                <label>Female</label>
                            </div>
                        </fieldset>

                        {this.renderPhoneInput("phone", "Phone*", "number")}
                        <br/>
                        {this.renderButton("SIGN UP", "btn btn-block submit-btn")}

                    </form>
                    <p>
                        {this.state.loading === true && <span><i style={{color: '#46807d',position:'absolute'}} className="fa fa-spin spinner-border"></i></span>}
                    </p>
                    <p>* By clicking <b>"sign up"</b> you are agreeing to the 
                    <a href="terms"> Terms of Service </a> and <a href="privacy">Privacy Policy</a>
                    </p>
                </div>
            </div>
        )
    }
}
