import React, { Component } from 'react'
import { Select } from '../../store/reducer/Category/action';
import { connect } from "react-redux";
import Globals from '../../Global/Constant';
import  API  from '../../Global/PrivateAPI';
class Category extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: [],
            active: false,
            category : [
                {'id':1, 'title':'Restauraunts', 'icon':'/assets/images/Restauraunts.png'},
                {'id':2, 'title':'Landmarks', 'icon':'/assets/images/Landmarks.png'},
                {'id':3, 'title':'Historical', 'icon':'/assets/images/Historical.png'},
                {'id':4, 'title':'Nature', 'icon':'/assets/images/Nature.png'}
            ]
        }
    }
    componentDidMount(){


    }
    getValue(e) {
        var selected = e.currentTarget.dataset.id;
        this.props.CategorySelect(selected);

    }
    handleChange = e => {
        let option = e.currentTarget.dataset;
        this.setState({active: !this.state.active, activeIndex: option.id})
        var selected_id = option.id * 1;
        var selected_name = option.name;
        this.props.handleChangeCategory(selected_name);
        // console.log("category called", selected_id);
        this.props.CategorySelect(selected_id);
    }

    back = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }
    saveAndContinue = (e) => {
        e.preventDefault()
        this.props.nextStep()
    }

    render() {
        const { values } = this.props;
        let options;
        // if (this.state.search.length) {
        //     const searchPattern = new RegExp(this.state.search.map(term => `(?=.*${term})`).join(''), 'i');
        //     options = activites.filter(option => option.title.match(searchPattern));
        //     console.log(options);
        // } else {
        //     options = activites;
        // }

        return (
            <>
                <ul id="progressbar">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li className="active"></li>
                    <li></li>
                </ul>
                <fieldset className="tag-friend black-color activities">
                    <div className="upper-step row">
                        <div className="col-8 pl-0">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1"><img src="assets/images/activity-img.jpg" alt="" /></span>
                                </div>
                                <label className="btn btn-default veenme-upload">
                                    Category
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-group mb-3 col-12">
                            <input disabled = "disabled" type="text" className="form-control search-friend mr-0" value={values.category} placeholder="Select Category" aria-label="Recipient's username" onChange={(e) => this.setState({ search: e.target.value.split(' ') })} aria-describedby="basic-addon2" />
                        </div>
                    </div>
                    <div className="row tag-friend-list">
                        <div className="col-12">
                            <div className="tag-friend-list-scroll scrollbar">
                                {this.state.category.map((option) =>
                                    <ul key={option.id} className={(this.state.active & this.state.activeIndex == option.id) && 'cstm-checks'} data-id={option.id} data-name={option.title} onClick={this.handleChange}>
                                        <li>
                                            <img src={option.icon} className="img_circle" alt="" />
                                        </li>
                                        <li><p>{option.title}</p></li>
                                        <li><i className="far fa-check-circle"></i></li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <button className="next action-button btn" onClick={this.back}>Back</button>
                        </div>
                        <div className="col-6">
                            <button className="next action-button-previous btn" onClick={this.saveAndContinue}>Next</button>
                        </div>
                    </div>

                </fieldset >
            </>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        CategorySelect: longitude => dispatch(Select(longitude))
    }
};
export default connect(null, mapDispatchToProps)(Category);