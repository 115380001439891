import React, { Component } from "react"
import { Route, Switch } from "react-router-dom"
import Home from "./pages/HomePage"
import { NotFound } from "./component/NotFound"
import Notification from './pages/Notification/index'
import Message from './pages/Message/index'
import Explore from "./pages/Explore"
import Profile from "./pages/Profile"
import UserProfile from "./pages/Profile/user"
import Privacy from "./pages/CMS/privacy"
import Terms from "./pages/CMS/terms"
import ChangePassword from "./pages/Profile/change-password"
import PlaceProfile from "./pages/Profile/place"
import Login from "./pages/Login"

class Routes extends Component {

  render() {
    return (
      <Switch >
        <Route exact path="/" component={Login} />
        <Route exact path="/home" component={Home} />
        <Route path="/explore" component={Explore} />
        <Route path="/notifications" component={Notification} />
        <Route path="/messages" component={Message}/>
        <Route path="/profile" component={Profile} />
        <Route path="/user/:id" component={UserProfile} />
        <Route path="/place/:id" component={PlaceProfile} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/terms" component={Terms} />
        <Route path="/change-password" component={ChangePassword} />
        <Route component={NotFound} />
      </Switch>
    )
  }
}
export default Routes



