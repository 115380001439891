import API from "../Global/PrivateAPI";
import Globals from "../Global/Constant";

const followingList = {
    getList: function(url){
        return API.get((url) ? url : Globals.api + '/follow/following-list?q=').then(data => {
            return {item: (data.data.item !== undefined && data.data.item.length > 0) ? data.data.item : [] ,
                    next: (data.data._links.next === undefined)  ? null  : data.data._links.next.href,
                    totalCount: (data.data._meta.totalCount === undefined)  ? null  : data.data._meta.totalCount};
        });
    }
}

export default followingList;