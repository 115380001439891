import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/place/";



export function getPlaces(q) {
    return http.get(apiEndpoint+'search', {params: {q: q}});
}

export function getPlacePost() {
    return http.get(apiEndpoint+'place-posts');
}


export default {
    getPlaces,
    getPlacePost
};


