import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL ;

export async function updateUserInfo(info, interset) {
  const {data} = await http.post(apiEndpoint+ "/profile/update-user-info", { info, interset});
  return data.data;
}

export default {updateUserInfo};
