import { Button, Modal } from 'react-bootstrap';
import React, { useState } from 'react'
import auth from "../../services/authService";
import {ToastsContainer, ToastsStore} from "react-toasts";

export default function ForgetPassword() {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [password, setpassword] = useState('')
  const [rePassword, setRePassword] = useState(null)
  const [errors, setErrors] = useState(null)
  const [resetForm, setResetForm] = useState(false)

  const handleClose = () => {setShow(false); setResetForm(false);};
  const handleShow = () => setShow(true);
  const handleSendRequest = async () => {
      if(!resetForm) {
          try {
              // setShow(false);
              const {success, message} = await auth.forget(email);
              console.log('send request', success, message);
              ToastsStore.success("Send Request Successfully Please check your email");
              setResetForm(true);
          } catch (ex) {
              // console.log(ex)
              if (ex.response && (ex.response.status >= 400 && ex.response.status < 500)) {
                  // const err = { ...errors };
                  let err = null;
                  let res = ex.response.data.data;
                  console.log('errors', res)
                  res.forEach(element => {
                      err = element.message;
                  });
                  // this.state.serverErrorMessage = ex.response.data.message
                  setErrors(err);
                  console.log('errors', errors);
                  ToastsStore.error(err);
              }
          }
      }
      else
      {
          try {
              if(password != rePassword)
              {
                  ToastsStore.error("Password and Re Password not match");
                  return false;
              }
              const {success, message} = await auth.setforgetPassword(email, code, password);
              // console.log('send request', success, message);
              if(success == true)
              {
                  ToastsStore.success(message);
                  setShow(false);
                  return true;
              }
              else {
                  ToastsStore.error("Some things went wrong.");
              }
          } catch (ex) {
              // console.log(ex)
              if (ex.response && (ex.response.status >= 400 && ex.response.status < 500)) {
                  // const err = { ...errors };
                  let err = null;
                  let res = ex.response.data;
                  console.log('errors', res,ex.response)
                  err = res.message;
                  // this.state.serverErrorMessage = ex.response.data.message
                  setErrors(err);
                  console.log('errors', errors);
                  ToastsStore.error(err);
              }
          }
      }
  }

  return (
      <>
          <ToastsContainer store={ToastsStore}/>
        <span><a className={'forget-password'} href="#!" onClick={handleShow}>
            Forgot Password?
        </a></span>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
                {(resetForm) ? <Modal.Title>Reset Password</Modal.Title> : <Modal.Title>Forget Password</Modal.Title> }
          </Modal.Header>
            <form>
          <Modal.Body>
              <div className="row" style={{display : (resetForm) ? 'none' : 'block' }}>
                  <div className="col-sm-10">
                      <div className="form-group">
                          <label style={{color:"black"}}>Please enter your email address</label>
                          <input onChange = {(e) => setEmail(e.target.value)} name='email' type='email' className='form-control' required='true' placeholder='Email*' />
                      </div>
                  </div>
              </div>
              <div className="row" style={{display : (resetForm)? 'block' : 'none' }}>
                  <p> &nbsp; Please follow the instructions to reset the password</p>
                  <div className="col-sm-10">
                      <div className="form-group">
                          <label style={{color:"black"}}>Enter 4 digits code sent to your phone number</label>
                          <input onChange = {(e) => setCode(e.target.value)} name='code' type='number' className='form-control' required='true' placeholder='Code*' />
                      </div>
                      <div className="form-group">
                          <label style={{color:"black"}}>Enter New Password</label>
                          <input onChange = {(e) => setpassword(e.target.value)} name='password' type='password' className='form-control' required='true' placeholder='Password*' />
                      </div>
                      <div className="form-group">
                          <label style={{color:"black"}}>Confirm New Password</label>
                          <input onChange = {(e) => setRePassword(e.target.value)} name='re-password' type='password' className='form-control' required='true' placeholder='Re-Password*' />
                      </div>
                  </div>
              </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSendRequest}>
              Send
            </Button>
          </Modal.Footer>
            </form>
        </Modal>
      </>
  );
}
// render(<ForgetPassword />);