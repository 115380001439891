import API from "../Global/PrivateAPI";
import Globals from "../Global/Constant";

const placeProfile = {
    getdetail: function(id){
        return API.get( Globals.api + '/place?place_id='+id).then(data => {
            return {data: (data.data !== undefined) ? data.data : [] };
        });
    },
}

export default placeProfile;