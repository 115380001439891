import React, { Component } from 'react'
import reviewService from '../../services/reviewService'
import ReactTimeAgo from 'react-time-ago'
import {Link} from "react-router-dom";

export default class Review extends Component {

    constructor(props) {
        super(props)
        this.state = {
            reviews: []
        }
    }

    componentDidMount() {
        this.getReviews();
    }

    async getReviews() {
        await reviewService.getList().then(res => {
            this.setState({
                reviews: res.data.data.item
            });
        });
    }

    timeSince(date) {

  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

  convertUTCDateToLocalDate = (date) => {
    date = new Date(date);
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return this.timeSince(newDate);
  }



    render() {
        return (
            <>
                {
                    this.state.reviews.map((review, i) => {
                        return (
                            <div className="timeline_box review_box_tab" key={"review_" + i}>
                                <div className="box_inner d-flex align-items-start">
                                    <img src={review.user_profile_image} className="img-fluid img_circle" />
                                    <div className="box_inner_content">
                                        <div className="name_div">
                                            <h4 className="d-inline-block">{review.full_name}
                                                <span> rated</span>
                                            </h4>
                                            <Link to={"place/" + review.place_id}><span> {review.place}</span></Link>
                                        </div>

                                        <div className="middle_box">
                                            <h5> {review.full_name} rated<i className="fas fa-star"></i><span>{review.rating}</span>
                                                <span className="review-hours hour_class">{review.created !== undefined ? this.convertUTCDateToLocalDate(review.created) : ''}</span></h5>
                                            <p>{review.review}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </>
        )
    }

}
